.p-table-container,
.p-container,
.growable,
.p-datatable-wrapper {
	flex-grow: 2;
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.p-table-container {
	.p-card-body,
	.p-card,
	.p-card-content {
		flex-grow: 2;
		display: flex;
		flex-direction: column;
	}
}

.row-inactive {
	color: #a7a7a7 !important;
}

.row-blocked {
	color: red !important;
}

.customize {
	.p-card-body {
		padding: 8px 2rem 1rem 2rem !important;
	}
}

.botonDialog .p-button-icon {
	color: white !important;
	margin-left: 5px;
	span {
		font-size: 1rem;
	}
}
