@media (min-width: 1025px) {
	.layout-wrapper {
		&.layout-overlay {
			.layout-sidebar {
				left: -250px;
			}

			.layout-topbar {
				left: 0;
			}

			.layout-main,
			.layout-footer {
				margin-left: 0;
			}

			&.layout-overlay-sidebar-active {
				.layout-sidebar {
					left: 0;
				}
			}
		}

		&.layout-static {
			.layout-sidebar {
				left: 0;
			}

			.layout-topbar,
			.layout-footer {
				left: 0;
			}

			.layout-main {
				margin-left: 130px;
			}

			&.layout-static-sidebar-inactive {
				.layout-sidebar {
					left: -150px;
				}

				.layout-topbar {
					left: 0;
				}

				.layout-main,
				.layout-footer {
					margin-left: -90px;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.layout-wrapper {
		.layout-topbar {
			left: 0;
		}

		.layout-footer {
			position: absolute;
			bottom: 0;
		}

		.layout-main,
		.layout-footer {
			margin-left: 0;
		}

		.layout-sidebar {
			left: -250px;
			margin-top: 50px;
		}

		&.layout-mobile-sidebar-active {
			.layout-sidebar {
				left: -0;
			}

			.layout-mask {
				display: block;
			}
		}
	}

	.body-overflow-hidden {
		overflow: hidden;
	}
}

.body-overflow-hidden {
	overflow: hidden;
}
