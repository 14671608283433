.height30px {
	height: 30px;
}
.color-blue {
	color: #006699;
}
.floatRigth {
	float: right;
}
.floatLeft {
	float: left;
}
.margintop-10px {
	margin-top: -10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
