.layout-topbar {
	position: fixed;
	height: 50px;
	padding: $top-bar-padding;
	color: #663366;
	z-index: 999;
	font-size: $top-bar-font-size;
	right: 0;
	@include clearfix();
	@include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
	@include transition(left $transitionDuration);

	.layout-topbar-icons {
		float: right;
		display: block;
		-moz-animation-duration: 0.5s;
		-webkit-animation-duration: 0.5s;
		animation-duration: 0.5s;

		button {
			position: relative;
			color: $topbarItemColor;
			margin-left: 20px;
			display: inline-block;
			text-decoration: none;
			@include transition(color $transitionDuration);
			overflow: visible;

			&:hover {
				color: $topbarItemHoverColor;
			}

			span {
				&.layout-topbar-icon {
					font-size: 2em;
				}

				&.layout-topbar-item-text {
					font-size: 20px;
					display: none;
				}

				&.layout-topbar-badge {
					position: absolute;
					font-size: 10px;
					right: -5px;
					top: -5px;
					width: 16px;
					height: 16px;
					text-align: center;
					line-height: 16px;
					color: $topbarItemBadgeColor;
					background-color: $topbarItemBadgeBgColor;
					@include border-radius(50%);
				}
			}
		}

		.layout-topbar-search {
			padding: 0;
			position: relative;
			display: inline-block;
			top: -4px;

			input {
				display: inline-block;
				border: 0 none;
				font-size: $fontSize;
				background: transparent;
				border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
				outline: 0 none;
				-webkit-box-shadow: none;
				box-shadow: none;
				color: $topbarSearchInputColor;
				width: 100px;
				padding: 1px 20px 1px 1px;
				margin: 0px;
				@include border-radius(2px);

				&::-webkit-input-placeholder {
					color: $topbarSearchInputColor;
					opacity: 0.7;
					@include transition(opacity $transitionDuration);
				}
				&:-moz-placeholder {
					color: $topbarSearchInputColor;
					opacity: 0.7;
					@include transition(opacity $transitionDuration);
				}
				&::-moz-placeholder {
					color: $topbarSearchInputColor;
					opacity: 0.7;
					@include transition(opacity $transitionDuration);
				}
				&:-ms-input-placeholder {
					color: $topbarSearchInputColor;
					opacity: 0.7;
					@include transition(opacity $transitionDuration);
				}
			}

			.layout-topbar-search-icon {
				font-size: 18px;
				position: absolute;
				top: -1px;
				right: 0px;
			}

			&:hover {
				input {
					border-bottom-color: $topbarItemHoverColor;
					&::-webkit-input-placeholder {
						opacity: 1;
					}
					&:-moz-placeholder {
						opacity: 1;
					}
					&::-moz-placeholder {
						opacity: 1;
					}
					&:-ms-input-placeholder {
						opacity: 1;
					}
				}

				.layout-topbar-search-icon {
					color: $topbarItemHoverColor;
				}
			}
		}
	}

	.layout-menu-button {
		cursor: pointer;
		text-decoration: none;
		color: $topbarItemColor;
		@include transition(color $transitionDuration);

		span {
			font-size: 2em;
		}

		&:hover {
			color: $topbarItemHoverColor;
		}
	}

	button {
		cursor: pointer;
	}
}
