* {
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	font-family: $font-family;
	font-size: $fontSize;
	color: black;
	background-color: $body-background-color !important;
	margin: 0;
	padding: 0;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	height: 100%;
}

a {
	text-decoration: none;
}
#root {
	height: 100%;
}
.layout-wrapper {
	padding: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
}

.invalid-feedback-show {
	color: red;
}

.editIcon:hover {
	cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #2196f3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.formulario-3-campos {
	display: inline-block;
}

.formulario-1-campo {
	float: left;
}

.btn-zise {
	size: 50px;
}
.btn-bottom {
	margin-bottom: 0px;
}
.react-datepicker__input-container {
	input:hover {
		border-color: blue;
	}
	input::selection {
		border-color: blue;
	}
}
.react-datepicker__input-container {
	input {
		flex: 1 1 auto;
		width: 1%;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		width: 1%;
		flex: 1 1 auto;
		width: 1%;
		margin: 0;
		width: 100%;
		flex: 1 1 auto;
		width: 1%;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		width: 1%;
		flex: 1 1 auto;
		width: 1%;
		margin: 0;
		width: 100%;
		box-sizing: border-box;
		margin: 0;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
			'Segoe UI Symbol';
		font-size: 1rem;
		color: #495057;
		background: #ffffff;
		padding: 0.5rem 0.5rem;
		border: 1px solid #ced4da;
		-webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s,
			-webkit-box-shadow 0.2s;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s,
			-webkit-box-shadow 0.2s;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s,
			box-shadow 0.2s;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s,
			box-shadow 0.2s, -webkit-box-shadow 0.2s;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 3px;
		width: 100%;
	}
}
