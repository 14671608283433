.table-cliente {
	border-radius: 10px;
	padding: 20px 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.table-info-cliente {
	height: 200px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	@media (max-width: 1124px) {
		height: 205px;
	}
}

.div-data-cliente {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	padding: 8px 4em 8px 8px;
	font-size: 14px;

	@media (max-width: 1124px) {
		padding: 4px;
		font-size: 12px;
	}
}

.text-bold {
	padding-right: 2em;
	width: 170px;

	@media (max-width: 1124px) {
		width: 120px;
		padding-right: 0em;
	}
}


.photo-container{
	width: 120px;
	height: 120px;
}


.userphoto {
	border-radius: 100%;
	width: 120px;
	height: 120px;
	object-fit: cover;
}

.userphoto-placeholder {
	border-radius: 100%;
	font-size: 80px;
	padding: 20px;
	background-color: #663366;
	color: #ffffff;
}

.nobutton{
	background-color: white !important;
	border: none;
	> span{
		color: #663366 !important;
	}
}

.edit-icon {
	background-color: #663366;
	padding: 4px !important;
	border-radius: 100%;
	color: white;
	fill: white;
	z-index: 99;
	position: relative;
	right: 40px;
	border: 2px solid #d9d9d9 !important;
	top: 2px;
	margin: 0 !important;
	width: 32px !important;
	overflow: inherit;

	&:focus {
		background-color: #663366;
		box-shadow: none;
	}

	span {
		font-size: 12px !important;
	}
}

.icon-pencil::before {
	margin-right: 2px;
	top: 0;
}

.button-block {
	background-color: #669999;
	border-color: #669999;

	&:hover,
	&:focus {
		background: #669999 !important;
		border-color: #669999 !important;
	}

	&:hover {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	}
}

.button-unblock {
	background: #cc3333 !important;
	border-color: #cc3333 !important;

	&:hover,
	&:focus {
		background: #cc3333 !important;
		border-color: #cc3333 !important;
	}

	&:hover {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	}
}

.table-not-block {
	border: 2px solid #669999;
	border-radius: 10px;
}

.table-block {
	border: 2px solid $error-color;
	border-radius: 10px;
}

.color-div {
	width: 50px;
	background-color: #669999;
	float: left;
	border-radius: 10px 0 0 10px;
	margin: -2px;
}

.color-div-blocked {
	width: 50px;
	background-color: #cc3333;
	float: left;
	border-radius: 10px 0 0 10px;
	margin: -2px;
}
