.icon-button {
	background-color: white;
	color: $boton-color;
	border: none;

	&:hover {
		background-color: white !important;
		color: $boton-color !important;
	}

	&:disabled {
		svg {
			fill: #999999;
		}
	}
}

.label-dialog {
	width: 160px;
}

.custom-label-dialog {
	width: 220px;
	padding-left: 20px;
}

.state-input {
	width: 238px;
	padding-left: 12px;
	font-size: 14px;
}

.date-button {
	width: 90px;
	background: #ffffff;
	border: 1px solid #b899b8;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 5px 10px;
	font-family: Lato;
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
}

.dialog-inputtext {
	background: #ffffff;
	border: 1px solid #afafaf;
	border-radius: 4px;
	font-family: Lato;
	font-style: italic;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #afafaf;
	opacity: 0.5;
	width: 238px;
	height: 30px;

	&:hover,
	&:enabled:focus {
		border-color: #afafaf !important;
		background-color: #ffffff;
	}

	&.p-filled {
		color: black !important;
		background-color: #ffffff;
		opacity: 1 !important;
		border-color: #afafaf;
		font-style: normal !important;
	}
}

input[name=certificate_category_name] {
    width: 280px;
}


.dialog-inputtextarea {
	height: 84px;
	background: #ffffff;
	border: 1px solid #afafaf;
	border-radius: 4px;
	font-family: Lato;
	font-style: italic;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #afafaf;
	opacity: 0.5;
	width: 238px;

	&:hover,
	&:enabled:focus {
		border-color: #afafaf !important;
		background-color: #ffffff;
	}

	&.p-filled {
		color: black !important;
		background-color: #ffffff;
		opacity: 1 !important;
		border-color: #afafaf;
		font-style: normal !important;
	}
}

.confirmation-content {
	text-align: center;
}

.dialog-inputtextarea {
	height: 84px;
	background: #ffffff;
	border: 1px solid #afafaf;
	border-radius: 4px;
	font-family: Lato;
	font-style: italic;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #afafaf;
	opacity: 0.5;
	width: 238px;

	&:hover,
	&:enabled:focus {
		border-color: #afafaf !important;
		background-color: #ffffff;
	}

	&.p-filled {
		color: black !important;
		background-color: #ffffff;
		opacity: 1 !important;
		border-color: #afafaf;
		font-style: normal !important;
	}
}

.ref-doc-container{
	display: flex; 
	padding-left: 24px;
	flex-flow: column;
}

.ref-doc-container-column{
	flex-direction: column;
}

.ref-doc-filefield{
	display: flex;
	justify-content: center;
}

.label-ref-doc {
	margin: 0 !important;
}