@media (max-width: 768px) {
	.marginRigthIcon10px {
		margin-right: 0px;
	}
	.marginLeftMenu10Px {
		margin-left: 0px;
	}
	.marginLeftBoton10Px {
		margin-left: 0px;
	}
	.left-20 {
		margin-left: 0%;
	}
	.left-10 {
		margin-left: 0%;
	}
	.rigth-20 {
		margin-right: 0%;
	}
	.margin-top-200px {
		margin-top: 20px;
		display: inline-flex;
	}
	.margin-top-50px {
		margin-top: 20px;
		display: inline-flex;
	}
	.editIcon {
		margin-right: 10px;
	}
	.width-250px {
		width: 200px;
	}
	.react-datepicker-wrapper {
		width: 100%;
	}
}

@media (min-width: 769px) {
	.marginRigthIcon10px {
		margin-right: 10px;
	}
	.marginLeftMenu10Px {
		margin-left: 10px;
	}
	.marginLeftBoton10Px {
		margin-left: 10px;
	}
	.left-20 {
		margin-left: 20%;
	}
	.rigth-20 {
		margin-right: 20%;
	}
	.left-10 {
		margin-left: 10%;
	}
	.margin-top-200px {
		margin-top: 50px;
		display: inline-flex;
		align-items: center;
	}
	.margin-top-50px {
		margin-top: 50px;
		align-items: center;
	}
	.width-250px {
		width: 190px;
	}
	.editIcon {
		margin-right: 20px;
	}
	.react-datepicker-wrapper {
		width: 100%;
	}
	.float-left-date {
		float: left;
	}
}
@media (min-width: 1024px) {
	.float-left-date {
		float: left;
	}
}
.marginRigth10Px {
	margin-right: 0px;
}
.marginRigth10px {
	margin-right: 10px;
}
.p-inputtext-inputtext {
	padding: 0.5rem 0.5rem !important;
}
.width100 {
	width: 100%;
}
.width150Px {
	width: 200px;
}
.ctJDeu {
	border-bottom-color: #006699 !important;
	border-bottom-width: 3px !important;
}
.react-confirm-alert-overlay {
	background: rgba(255, 255, 255, 0.4) !important;
}

.p-float-label {
	.p-inputwrapper-filled ~ label,
	.p-filled ~ label,
	input:focus ~ label {
		font-size: 16px !important;
	}
}

.calendar-input {
	input {
		text-align: left !important;
	}
}
.searchBar {
	border: 2px solid #e5e5e5;
	border-radius: 4px;
	width: 400px;
	font-weight: 400;
	font-family: Roboto;
	font-size: 14px;
	height: 40px;

	&:hover,
	&:focus {
		border-color: #e5e5e5 !important;
	}

	&::placeholder {
		color: #929b9f;
	}
}

.searchButton {
	background-color: $boton-color;
	border: none;
	border-radius: 0px 3px 3px 0px;
	font-family: 'Roboto';
	font-size: 15px;
	height: 40px;
	width: 80px;
	text-align: center;
	line-height: 30px;
	padding: 0px;
	display: inline-block;
}

.fill-icon {
	fill: #663366;
}

.tables-scrollable {
	position: fixed;
	top: 140px;
	bottom: 10px;
	margin-top: 235px;
	overflow-y: scroll;
	padding-right: 4.2em;
	overflow-x: hidden;
	width: inherit;
	height: 60vh;
	z-index: 2000;


	@media (max-width: 1750px) and (min-width: 1551px) {
		height: 55vh;
	}

	@media (max-width: 1550px) {
		height: 45vh;
	}
}

.scrollable-movement {
	position: fixed;
	top: 140px;
	margin-top: 235px;
	overflow-y: scroll;
	padding-right: 4.2em;
	overflow-x: hidden;
	width: inherit;
	height: 55vh;
	z-index: 2000;

	@media (max-width: 1750px) and (min-width: 1581px) {
		margin-bottom: 10px;
	}

	@media (max-width: 1580px) and (min-width: 1381px) {
		margin-bottom: 100px;
		padding-bottom: 120px;
	}

	@media (max-width: 1380px) {
		margin-bottom: 100px;
		padding-bottom: 120px;
	}
}

.cliente-table {
	border-bottom: 2px solid #c4c4c4;
}

.movements-dropdown {
	min-width: 200px;
	z-index: 5000 !important;

	.p-placeholder {
		color: #010101 !important;
		font-style: normal !important;
		font-family: 'Roboto' !important;
		font-size: 16px !important;
	}
}

.filter-dropdown {
	@extend .movements-dropdown;
	margin-left: 10px;
	margin-right: 10px;
}

.calendar-field {
	label {
		width: 60px;
	}

	input {
		width: 100px !important;
	}
}

.movements-form {
	background: #f4f4f4;
	border-radius: 5px;
}

.error-balance {
	width: 320px;
}

.width120 {
	width: 120px;
}

.custom-field {
	:first-child {
		width: 145px;
	}
}

.movements-table {
	margin-top: 220px !important;
	.last-table {
		margin-bottom: 10px !important;
	}
}

.custom-loading {
	font-size: 1.75rem !important;
}

.custom-loading-wrapper {
	width: 175px;
	justify-content: center;
}

.loading {
	font-size: 3rem;
	color: #87189d;
}

.loadingCount {
	font-size: 16px;
	color: #87189d;
}

.customCard {
	height: 860px;
}

.customTable {
	height: 790px !important;
}

// .movementsTable {
// 	height: 1100px;

// 	.p-datatable-loading-overlay {
// 		display: flex;
// 		justify-content: center;
// 		align-items: flex-start;
// 		padding-top: 200px;
// 	}
// }

// .movementsCard {
// 	margin-bottom: 40px;
// }

.confirmDialog {
	width: 450px;

	.p-confirm-dialog-message {
		text-align: center;
		padding: 0 15px;
		line-height: 20px;
	}

	.p-dialog-footer {
		padding-top: 50px;
	}
}

.confirmDialogDocument {
	width: 480px;

	.p-confirm-dialog-message {
		text-align: center;
		padding: 0 15px;
		line-height: 20px;
	}

	.p-dialog-footer {
		padding-top: 50px;
	}
}

.rejectButton {
	background: #ababab;
	border-color: #ababab;
	margin-right: 15px !important;

	&:hover {
		background: #ababab !important;
		border-color: #ababab !important;
	}
}

.acceptDelete {
	background: #df2828;
	border-color: #df2828;

	&:hover {
		background: #df2828 !important;
		border-color: #df2828 !important;
	}
}

.loading-dialog {
	background: #cc6666;
	border-color: #cc6666;
}

.network-dropdown {
	border: 0px;
	min-width: 185px;
	.p-inputtext {
		font-size: 15px;
		text-align: left;
		padding-left: 44px;
	}
}

.p-dropdown-item-network-text {
	padding-left: 43px;
}

.network-dropdown-panel {
	top: 50px !important;
	width: 185px;
	.p-dropdown-items {
		.p-dropdown-item {
			width: 100%;
			align-items: flex-start;
			font-size: 15px;
			display: flex;
			flex-direction: column;
			padding: 0;
			justify-content: center;
			border-bottom: 2px solid #f3f3f3;
			height: 55px;
		}
		.p-dropdown-item.p-highlight {
			background: #f3f3f3;
			&:hover {
				background: white !important;
			}
		}
		.p-dropdown-item:last-child {
			border-bottom: none;
		}
	}
}

.network-list {
	margin: 0px;
}

.subtitle-dialog {
	font-size: 12px;
	color: #999999;
	font-weight: 700;
	font-family: Roboto;
	letter-spacing: 0.03em;
	line-height: 14px;
}

.small-dropdown {
	min-width: 140px !important;
}

.separator-line {
	border-top: 1px solid #c4c4c4;
	border-bottom: none;
}

.small-separator-line {
	border-top: 1px solid #ebeaea;
	border-bottom: none;
	width: 320px;
}

.networks-div {
	width: 130px;
}

.half {
	width: 50%;
}

.network-list {
	margin: 0px;
	padding-left: 0px;
}

.recover-password-text {
	color: #333333;
	text-decoration: underline;
	font-size: 14px;
	font-family: 'Lato';
	letter-spacing: 0.25px;
	padding-top: 18px;
	padding-bottom: 9px;
	border: none;
	background-color: white;
	cursor: pointer;
}

.error-message {
	text-align: center;
	color: red;
	font-size: 17px;
}

.requisites-password-title {
	font-size: 14px;
	font-family: Lato;
	color: #333333;
}

.requisites-password {
	list-style-type: none;
	padding: 0;

	i {
		font-size: 15px;
		margin-right: 10px;
	}
	li {
		padding-bottom: 7px;
		font-size: 14px;
		font-family: Lato;
		color: #333333;
	}
}

.success-requisite {
	color: #308604 !important;
}

.error-requisite {
	color: #cb2c09 !important;
}

.third {
	width: 33%;
}

.custom-width {
	width: 220px;
}

.info-data {
	text-align: justify;
	margin-left: 10px;
}

.walletdef-image {
	margin-top: 10px;
	width: 350px;
	margin-left: 50px;
}

.walletdef-label {
	min-width: 200px;
}

.pocketdef-label {
	width: 150px;
}

.pocketdef-dates-label {
	width: 80px;
}

.walletdef-dates-label {
	width: 60px;
}

.forgot-password-intro {
	width: 350px;
	line-height: 22px;
	font-size: 16px;
	font-family: Lato;
}

.email-inputtext {
	width: 350px !important;
	padding-left: 0 !important;

	&:hover,
	&:focus {
		border-color: #c4c4c4 !important;
	}

	&::placeholder {
		color: #929b9f;
	}
}

.code-inputtext {
	width: 300px !important;
	padding-left: 0 !important;

	&::placeholder {
		color: #929b9f;
	}
}

.p-card-content {
	padding-bottom: 20px;
}

.forgot-password-code {
	color: #707070;
}

.change-password-text {
	color: #333333;
}

.success-message {
	color: green;
}

.login-placeholder {
	&::placeholder {
		color: #929b9f !important;
	}
}

.menu-user {
	padding: 0 !important;
	top: 50px !important;
	width: min-content;
	position: fixed;

	.p-menuitem {
		height: 50px;
		display: inline-flex !important;
		align-items: center;
		width: 100%;
		border-bottom: 2px solid #f3f3f3;

		&:last-child {
			border-bottom: none;
		}

		a {
			width: 100%;
			padding: 1.2rem 1rem;
		}
	}
}

.item-username {
	background-color: #f0ebf0;

	a {
		opacity: 1;
		justify-content: center;
	}
	span {
		color: #663366 !important;
		font-weight: 700;
	}
}

.export-csv {
	color: #ffffff !important;
	margin-left: 8px;
	height: 46px;

	a:-webkit-any-link {
		color: white;
		text-decoration: none;
	}
}

.export-csv.p-button-loading {
	width: 160px !important;

	span {
		margin-right: 5px;
	}
}

.apply-button {
	height: 46px;
}

.certificate-state {
	border-radius: 20px;
	padding: 6px;
	width: 160px;
}

.dialog-loading {
	height: 270px;
	display: flex;
	justify-content: center;
}

.description-photo {
	padding-top: 10px;
	font-size: 14px;
	font-weight: 600;
}

.docPhoto {
	height: 150px;
	max-width: 270px;
	object-fit: contain;
}

.date-margin{
	margin-left: 1.2rem;
}

.p-button.p-button-validate {
	background-color: #acb050 !important;
	border-color: transparent;
	border: transparent;
	float: right;
}

.p-button.p-button-edit {
	background-color: #9ca2a7 !important;
	border-color: transparent;
	border: transparent;
	align-items: left;
	float: left;
}

.p-button.p-button-renovate {
	float: right;
}

.p-button.p-button-cancel {
	background-color: #9ca2a7 !important;
	border-color: transparent;
	border: transparent;
}

.header-upload {
	background-color: #eff0d6;
	padding: 5px 10px;
}

.custom-document-dialog {
	width: 320px !important;
}

.edit-document-dialog{
	margin-left: 0.8rem !important;
	width: 210px !important;
}

.deny-label {
	margin-left: 12px;
}

.count-pending {
	background-color: rgb(255, 202, 99);
	font-size: 16px;
	border-radius: 0px 10px 10px 0px;
	color: #7E4F06;
	font-weight: 600;
	margin-bottom: 16px;
	padding: 16px;
}

.color-div-pending {
	background-color:#FAAA0F;
	width: 10px;
	border-radius: 10px 0px 0px 10px;
	height: 51px;
}

.width-deny{
	width: 210px !important;
}

.w-350{
	width:350px !important;
}

.no-files {
	font-size: 14px;
}

.create-document-dialog {
	padding-left: 15px;
}

.font-family-lato {
	font-family: Lato;
}

.create-custom-input {
	input {
	width: 335px;
	}

}

.description-label-file {
	font-size: 14px;
}

.mt-3 {
	margin-top: 0.75rem; /* 12px */
}

.divider-doc-reference {
	background-color: #e9ecef;
	height: 1px;
	width: 100%;
	margin: 0.3rem 0;
}

.document-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.files-divider {
	background-color:#D9D9D9; 
	width: 1px;
}

.details-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.custom-label {
	font-size:  14px;
	margin-top: 8px;
}