/* General */
$primary-color: #ffffff;
$primary-color-selected: #663366;
$box-shadow: rgba(255, 255, 255, 0.5);
$border-color: #663366;
$secondary-color: #663366;
$error-color: #df2828;
$text-color-secondary: #212121;
$text-color: #333333;
$font-family-title: 'Rams Regular';
$font-family: 'Roboto';
$font-family-bold: 'Roboto bold';
$font-family-ligth: 'Roboto ligth';
$background-color: #ffffff;
$hover-color: #663366;
$boton-hover-active: rgba(136, 15, 1, 0.16);
$components-border-color: #f5f5f5;
$components-border-color-header: #663366;
$boton-color: #663366;
$boton-color-pressed: #9c309c;
$boton-color-secondary: #666666;
$title-color: #707070;
$title-dialog-color: black;
$body-background-color: #e5e5e5;

$fontSize: 16px;
$bodyBgColor: $primary-color;
$textColor: #ffffff;
$textSecondaryColor: #333333;
$borderRadius: 10px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;

/* Menu Common */
$menuitemBadgeBgColor: #007be5;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;
$menuitemActiveRouteColor: $primary-color-selected;
$menu-font-size: 18px;

/* Menu Light */
$menuBgColorFirst: #ecebeb;
$menuBgColorLast: #ecebeb;
$menuitemColor: $text-color;
$menuitemHoverColor: $primary-color-selected;
$menuitemActiveColor: $primary-color-selected;
$menuitemActiveBgColor: #ffffff;
$menuitemBorderColor: rgba(207, 211, 224, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst: #4d505b;
$menuDarkBgColorLast: #3b3e47;
$menuitemDarkColor: #ffffff;
$menuitemDarkHoverColor: $primary-color-selected;
$menuitemDarkActiveColor: $primary-color-selected;
$menuitemDarkActiveBgColor: #2e3035;
$menuitemDarkBorderColor: rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor: $primary-color;
$topbarRightBgColor: $primary-color;
$topbarItemBadgeBgColor: #ef6262;
$topbarItemBadgeColor: #663366;
$topbarItemColor: #663366;
$topbarItemBackground: #663366;
$topbarItemHoverColor: #663366;
$topbarSearchInputBorderBottomColor: #663366;
$topbarSearchInputColor: #663366;
$top-bar-padding: 0.2em 0.4em 0em 0.4em;
$top-bar-font-size: 36px;

/* Footer */
$footerBgColor: #999999;
$footer-font-size: 10px;

/* Login */
$login-background-from: #cc3333;
$login-background-to: #663366;
$login-tittle: $primary-color;
