@charset "UTF-8";

:root {
	--surface-a: #ffffff;
	--surface-b: $background-color;
	--surface-c: #e9ecef;
	--surface-d: $components-border-color;
	--surface-e: #ffffff;
	--surface-f: #ffffff;
	--text-color: $text-color;
	--text-color-secondary: $text-color-secondary;
	--primary-color: $primary-color;
	--primary-color-text: #ffffff;
	--font-family: $font-family;
	--surface-0: #ffffff;
	--surface-50: #f9fafb;
	--surface-100: #f8f9fa;
	--surface-200: #e9ecef;
	--surface-300: $components-border-color;
	--surface-400: #ced4da;
	--surface-500: #adb5bd;
	--surface-600: $text-color-secondary;
	--surface-700: #495057;
	--surface-800: #343a40;
	--surface-900: $text-color;
	--content-padding: 1.25rem;
	--inline-spacing: 0.5rem;
}

* {
	box-sizing: border-box;
}

.p-component {
	font-size: 16px;
	font-weight: normal;
}

.p-component-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	transition-duration: 0.15s;
}

.p-disabled,
.p-component:disabled {
	opacity: 0.65;
	border: none;
}

.p-error {
	color: $error-color;
	font-weight: 400;
}

.p-text-secondary {
	color: $text-color-secondary;
}

.p-page-title {
	color: $title-color;
	font-weight: bold;
	font-size: 25px;
	padding-bottom: 1.5rem;
	font-family: $font-family;
}

.p-container {
	padding: 30px 5em 0px 10em;
}

.pi {
	font-size: 1.2rem;
}

.p-link {
	font-size: 1rem;
	font-family: $font-family;
	border-radius: 4px;
}
.p-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}

:root {
	--blue-50: #f3f8ff;
	--blue-100: #c5dcff;
	--blue-200: #97c1fe;
	--blue-300: #69a5fe;
	--blue-400: #3b8afd;
	--blue-500: #0d6efd;
	--blue-600: #0b5ed7;
	--blue-700: #094db1;
	--blue-800: #073d8b;
	--blue-900: #052c65;
	--green-50: #f4f9f6;
	--green-100: #c8e2d6;
	--green-200: #9ccbb5;
	--green-300: #70b595;
	--green-400: #459e74;
	--green-500: #198754;
	--green-600: #157347;
	--green-700: #125f3b;
	--green-800: #0e4a2e;
	--green-900: #0a3622;
	--yellow-50: #fffcf3;
	--yellow-100: #fff0c3;
	--yellow-200: #ffe494;
	--yellow-300: #ffd965;
	--yellow-400: #ffcd36;
	--yellow-500: #ffc107;
	--yellow-600: #d9a406;
	--yellow-700: #b38705;
	--yellow-800: #8c6a04;
	--yellow-900: #664d03;
	--cyan-50: #f3fcfe;
	--cyan-100: #c5f2fb;
	--cyan-200: #97e8f9;
	--cyan-300: #69def6;
	--cyan-400: #3bd4f3;
	--cyan-500: #0dcaf0;
	--cyan-600: #0baccc;
	--cyan-700: #098da8;
	--cyan-800: #076f84;
	--cyan-900: #055160;
	--pink-50: #fdf5f9;
	--pink-100: #f5cee1;
	--pink-200: #eda7ca;
	--pink-300: #e681b3;
	--pink-400: #de5a9b;
	--pink-500: #d63384;
	--pink-600: #b62b70;
	--pink-700: #96245c;
	--pink-800: #761c49;
	--pink-900: #561435;
	--indigo-50: #f7f3fe;
	--indigo-100: #dac6fc;
	--indigo-200: #bd98f9;
	--indigo-300: #a06bf7;
	--indigo-400: #833df4;
	--indigo-500: #6610f2;
	--indigo-600: #570ece;
	--indigo-700: #470ba9;
	--indigo-800: #380985;
	--indigo-900: #290661;
	--teal-50: #f4fcfa;
	--teal-100: #c9f2e6;
	--teal-200: #9fe8d2;
	--teal-300: #75debf;
	--teal-400: #4ad3ab;
	--teal-500: #20c997;
	--teal-600: #1bab80;
	--teal-700: #168d6a;
	--teal-800: #126f53;
	--teal-900: #0d503c;
	--orange-50: #fff9f3;
	--orange-100: #ffe0c7;
	--orange-200: #fec89a;
	--orange-300: #feaf6d;
	--orange-400: #fd9741;
	--orange-500: #fd7e14;
	--orange-600: #d76b11;
	--orange-700: #b1580e;
	--orange-800: #8b450b;
	--orange-900: #653208;
	--bluegray-50: #f8f9fb;
	--bluegray-100: #e0e4ea;
	--bluegray-200: #c7ced9;
	--bluegray-300: #aeb9c8;
	--bluegray-400: #95a3b8;
	--bluegray-500: #7c8ea7;
	--bluegray-600: #69798e;
	--bluegray-700: #576375;
	--bluegray-800: #444e5c;
	--bluegray-900: #323943;
	--purple-50: #f8f6fc;
	--purple-100: #dcd2f0;
	--purple-200: #c1aee4;
	--purple-300: #a68ad9;
	--purple-400: #8a66cd;
	--purple-500: #6f42c1;
	--purple-600: #5e38a4;
	--purple-700: #4e2e87;
	--purple-800: #3d246a;
	--purple-900: #2c1a4d;
}

.p-autocomplete .p-autocomplete-loader {
	right: 0.75rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
	right: 3.107rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
	padding: 0.25rem 0.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
	border-color: #ced4da;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
	padding: 0.25rem 0;
}
.p-autocomplete
	.p-autocomplete-multiple-container
	.p-autocomplete-input-token
	input {
	font-family: $font-family;
	font-size: 1rem;
	color: $text-color;
	padding: 0;
	margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
	padding: 0.25rem 0.75rem;
	margin-right: 0.5rem;
	background: $primary-color;
	color: #ffffff;
	border-radius: 4px;
}
.p-autocomplete
	.p-autocomplete-multiple-container
	.p-autocomplete-token
	.p-autocomplete-token-icon {
	margin-left: 0.5rem;
}
.p-autocomplete.p-invalid.p-component > .p-inputtext {
	border-color: $error-color;
}

.p-autocomplete-panel {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: none;
}
.p-autocomplete-panel .p-autocomplete-items {
	padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
	margin: 0;
	padding: 0.5rem 1.5rem;
	border: 0 none;
	color: $text-color;
	background: transparent;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
	color: $text-color;
	background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
	color: #ffffff;
	background: $primary-color;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
	margin: 0;
	padding: 0.75rem 1rem;
	color: $text-color;
	background: #ffffff;
	font-weight: 600;
}

.p-calendar.p-invalid.p-component > .p-inputtext {
	border-color: $error-color;
}

.p-calendar {
	background: #ffffff;
	text-align: center;
	border: 1px solid #d2d2d2;
	border-radius: 4px;
	.p-inputtext:enabled:focus,
	.p-inputtext:enabled:hover {
		border-color: #d2d2d2;
	}
	.p-filled {
		color: black !important;
		opacity: 1 !important;
		border: none;
		font-style: normal !important;
	}
}

.p-calendar .p-inputtext {
	font-family: Lato;
	color: #afafaf;
	text-align: center;
	font-style: italic;
	font-size: 14px;
	opacity: 0.5;
	border: none;
	height: 30px;
}

.p-datepicker {
	padding: 0;
	background: #ffffff;
	color: $text-color;
	border: 1px solid #ced4da;
	border-radius: 4px;
	z-index: 5002 !important;
}
.p-datepicker:not(.p-datepicker-inline) {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
	background: $background-color;
}
.p-datepicker .p-datepicker-header {
	padding: 0.5rem;
	color: $text-color;
	background: #ffffff;
	font-weight: 600;
	margin: 0;
	border-bottom: 1px solid $components-border-color;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
	line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
	margin-right: 0.5rem;
}
.p-datepicker table {
	font-size: 1rem;
	margin-bottom: 1rem;
}
.p-datepicker table th {
	padding: 0.5rem;
}
.p-datepicker table th > span {
	width: 2.5rem;
	height: 2.5rem;
}
.p-datepicker table td {
	padding: 0.25rem 0.5rem;
}
.p-datepicker table td:first-of-type {
	padding-left: 1.25rem;
}
.p-datepicker table td:last-child {
	padding-right: 1.25rem;
}
.p-datepicker table td > span {
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 4px;
	transition: box-shadow 0.15s;
	border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
	color: #333333;
	background: #ced4da;
}
.p-datepicker table td > span:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-datepicker table td.p-datepicker-today > span {
	background: $primary-color;
	color: $text-color;
	border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
	color: #333333;
	background: #ced4da;
}
.p-datepicker .p-datepicker-buttonbar {
	padding: 1rem 0;
	border-top: 1px solid $components-border-color;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
	width: auto;
}
.p-datepicker .p-timepicker {
	border-top: 1px solid $components-border-color;
	padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-datepicker .p-timepicker button:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-datepicker .p-timepicker button:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-datepicker .p-timepicker button:last-child {
	margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
	font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
	padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
	border-top: 0 none;
}
.p-datepicker .p-monthpicker {
	margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
	padding: 0.5rem;
	transition: box-shadow 0.15s;
	border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
	color: #ffffff;
	background: $primary-color;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
	border-right: 1px solid $components-border-color;
	padding-right: 0;
	padding-left: 0;
	padding-top: 0;
	padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
	padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
	padding-right: 0;
	border-right: 0 none;
}
.p-datepicker:not(.p-disabled)
	table
	td
	span:not(.p-highlight):not(.p-disabled):hover {
	background: #e9ecef;
}
.p-datepicker:not(.p-disabled)
	table
	td
	span:not(.p-highlight):not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-datepicker:not(.p-disabled)
	.p-monthpicker
	.p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
	background: #e9ecef;
}
.p-datepicker:not(.p-disabled)
	.p-monthpicker
	.p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}

.p-datepicker-mask.p-component-overlay {
	background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 769px) {
	.p-datepicker table th,
	.p-datepicker table td {
		padding: 0;
	}
}
.p-cascadeselect {
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	border-radius: 4px;
}
.p-cascadeselect:not(.p-disabled):hover {
	border-color: #ced4da;
}
.p-cascadeselect:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-cascadeselect .p-cascadeselect-label {
	background: transparent;
	border: 0 none;
	padding: 0.5rem 0.75rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
	color: $text-color-secondary;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
	outline: 0 none;
	box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
	background: transparent;
	color: #495057;
	width: 2.357rem;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.p-cascadeselect.p-invalid.p-component {
	border-color: $error-color;
}

.p-cascadeselect-panel {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: none;
}
.p-cascadeselect-panel .p-cascadeselect-items {
	padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
	margin: 0;
	border: 0 none;
	color: $text-color;
	background: transparent;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-cascadeselect-panel
	.p-cascadeselect-items
	.p-cascadeselect-item
	.p-cascadeselect-item-content {
	padding: 0.5rem 1.5rem;
}
.p-cascadeselect-panel
	.p-cascadeselect-items
	.p-cascadeselect-item
	.p-cascadeselect-item-content:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-cascadeselect-panel
	.p-cascadeselect-items
	.p-cascadeselect-item.p-highlight {
	color: #ffffff;
	background: $primary-color;
}
.p-cascadeselect-panel
	.p-cascadeselect-items
	.p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
	color: $text-color;
	background: #e9ecef;
}
.p-cascadeselect-panel
	.p-cascadeselect-items
	.p-cascadeselect-item
	.p-cascadeselect-group-icon {
	font-size: 0.875rem;
}

.p-input-filled .p-cascadeselect {
	background: $background-color;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
	background-color: $background-color;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
	background-color: $background-color;
}

.p-checkbox {
	width: 20px;
	height: 20px;
}
.p-checkbox .p-checkbox-box {
	border: 2px solid #ced4da;
	background: #ffffff;
	width: 20px;
	height: 20px;
	color: $text-color;
	border-radius: 4px;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
	transition-duration: 0.15s;
	color: #ffffff;
	font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
	border-color: $primary-color;
	background: $primary-color;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
	border-color: $border-color;
	background: $border-color;
	color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
	border-color: #ced4da;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
	border-color: $border-color;
	background: $border-color;
	color: #ffffff;
}
.p-checkbox.p-invalid > .p-checkbox-box {
	border-color: $error-color;
}

.p-input-filled .p-checkbox .p-checkbox-box {
	background-color: $background-color;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
	background: $primary-color;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
	background-color: $background-color;
}
.p-input-filled
	.p-checkbox:not(.p-checkbox-disabled)
	.p-checkbox-box.p-highlight:hover {
	background: $border-color;
}

.p-highlight .p-checkbox .p-checkbox-box {
	border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
	padding: 0.25rem 0.75rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
	border-color: #ced4da;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-chips .p-chips-multiple-container .p-chips-token {
	padding: 0.25rem 0.75rem;
	margin-right: 0.5rem;
	background: $primary-color;
	color: #ffffff;
	border-radius: 4px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
	margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
	padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
	font-family: $font-family;
	font-size: 1rem;
	color: $text-color;
	padding: 0;
	margin: 0;
}
.p-chips.p-invalid.p-component > .p-inputtext {
	border-color: $error-color;
}

.p-colorpicker-preview {
	width: 2rem;
	height: 2rem;
}

.p-colorpicker-panel {
	background: $text-color;
	border-color: $text-color;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
	border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
	box-shadow: none;
}

.p-dropdown {
	background: #ffffff;
	border: 1px solid #d6d6d6;
	height: 30px;
	min-width: 238px;
	align-items: center;
	z-index: 5001;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	border-radius: 5px;
}
.p-dropdown:not(.p-disabled):hover {
	border-color: #d6d6d6;
}
.p-dropdown:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: #d6d6d6;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
	padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
	background: transparent;
	border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
	font-style: italic;
	font-family: Lato;
	font-size: 14px;
	line-height: 20px;
	color: #afafaf;
}
.p-dropdown .p-dropdown-label:enabled:focus {
	outline: 0 none;
	box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
	background: transparent;
	color: $secondary-color;
	width: 2.357rem;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
	color: $secondary-color;
	right: 2.357rem;
}
.p-dropdown.p-invalid.p-component {
	border-color: $error-color;
}

.p-dropdown-panel {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: none;
	z-index: 5001 !important;
}
.p-dropdown-panel .p-dropdown-header {
	padding: 0.75rem 1.5rem;
	border-bottom: 1px solid $components-border-color;
	color: $text-color;
	background: $background-color;
	margin: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
	padding-right: 1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
	right: 0.75rem;
	color: #495057;
}
.p-dropdown-panel
	.p-dropdown-header
	.p-dropdown-clearable-filter
	.p-dropdown-filter {
	padding-right: 3.5rem;
}
.p-dropdown-panel
	.p-dropdown-header
	.p-dropdown-clearable-filter
	.p-dropdown-filter-clear-icon {
	right: 2.5rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
	margin: 0;
	padding: 8px 0 8px 16px;
	font-size: 14px;
	border: 0 none;
	font-family: Roboto;
	color: black;
	background: transparent;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	color: black;
	background: #dddddd;
}
.p-dropdown-panel
	.p-dropdown-items
	.p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
	color: $text-color;
	background: #dddddd;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
	margin: 0;
	padding: 0.75rem 1rem;
	color: $text-color;
	background: #ffffff;
	font-weight: 600;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
	padding: 0.5rem 1.5rem;
	color: $text-color;
	background: transparent;
}

.p-input-filled .p-dropdown {
	background: $background-color;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
	background-color: $background-color;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
	background-color: $background-color;
}

.p-editor-container .p-editor-toolbar {
	background: $background-color;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
	border: 1px solid $components-border-color;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
	stroke: $text-color-secondary;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
	fill: $text-color-secondary;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
	border: 0 none;
	color: $text-color-secondary;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker
	.ql-picker-label:hover {
	color: $text-color;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker
	.ql-picker-label:hover
	.ql-stroke {
	stroke: $text-color;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker
	.ql-picker-label:hover
	.ql-fill {
	fill: $text-color;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker.ql-expanded
	.ql-picker-label {
	color: $text-color;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker.ql-expanded
	.ql-picker-label
	.ql-stroke {
	stroke: $text-color;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker.ql-expanded
	.ql-picker-label
	.ql-fill {
	fill: $text-color;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker.ql-expanded
	.ql-picker-options {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
	border-radius: 4px;
	padding: 0.5rem 0;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker.ql-expanded
	.ql-picker-options
	.ql-picker-item {
	color: $text-color;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker.ql-expanded
	.ql-picker-options
	.ql-picker-item:hover {
	color: $text-color;
	background: #e9ecef;
}
.p-editor-container
	.p-editor-toolbar.ql-snow
	.ql-picker.ql-expanded:not(.ql-icon-picker)
	.ql-picker-item {
	padding: 0.5rem 1.5rem;
}
.p-editor-container .p-editor-content {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-editor-container .p-editor-content.ql-snow {
	border: 1px solid $components-border-color;
}
.p-editor-container .p-editor-content .ql-editor {
	background: #ffffff;
	color: #495057;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
	color: $text-color;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
	stroke: $text-color;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
	fill: $text-color;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
	color: $primary-color;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
	stroke: $primary-color;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
	fill: $primary-color;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container
	.ql-snow.ql-toolbar
	.ql-picker-label.ql-active
	.ql-picker-label,
.p-editor-container
	.ql-snow.ql-toolbar
	.ql-picker-item.ql-selected
	.ql-picker-label {
	color: $primary-color;
}

.p-inputgroup-addon {
	background: #e9ecef;
	color: #495057;
	border-top: 1px solid #ced4da;
	border-left: 1px solid #ced4da;
	border-bottom: 1px solid #ced4da;
	padding: 0.5rem 0.75rem;
	min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
	border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
	border-radius: 0;
	margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
	border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
	z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
	z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
	width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
	width: 2.357rem;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
	border-color: $error-color;
}

.p-inputswitch {
	width: 3rem;
	height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
	background: #ced4da;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	border-radius: 4px;
}
.p-inputswitch .p-inputswitch-slider:before {
	background: #ffffff;
	width: 1.25rem;
	height: 1.25rem;
	left: 0.25rem;
	margin-top: -0.625rem;
	border-radius: 4px;
	transition-duration: 0.15s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
	transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
	background: #ced4da;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background: $primary-color;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
	background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
	.p-inputswitch-slider {
	background: $primary-color;
}
.p-inputswitch.p-invalid {
	border-color: $error-color;
}

.p-inputtext {
	font-family: $font-family;
	font-size: 14px;
	color: black;
	background: #ffffff;
	padding: 0.5rem 0.75rem;
	border: 1px solid #ced4da;
	border-top: none;
	border-left: none;
	border-right: none;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	appearance: none;
	border-radius: 0px;
}
.p-inputtext:enabled:hover {
	border-color: #663366;
}
.p-inputtext:enabled:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: #663366;
	caret-color: black;
}
.p-inputtext.p-invalid.p-component {
	border-color: $error-color;
}
.p-inputtext.p-inputtext-sm {
	font-size: 0.875rem;
	padding: 0.4375rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
	font-size: 1.25rem;
	padding: 0.625rem 0.9375rem;
}

.p-float-label > label {
	left: 0.75rem;
	color: $text-color-secondary;
	transition-duration: 0.15s;
}

.p-float-label > label.p-error {
	color: $error-color;
}

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
	left: 0.75rem;
	color: #495057;
}

.p-input-icon-left > .p-inputtext {
	padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
	left: 2.5rem;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
	right: 0.75rem;
	color: #495057;
}

.p-input-icon-right > .p-inputtext {
	padding-right: 2.5rem;
	width: 100%;
}

::-webkit-input-placeholder {
	color: $text-color-secondary;
}

:-moz-placeholder {
	color: $text-color-secondary;
}

::-moz-placeholder {
	color: $text-color-secondary;
}

:-ms-input-placeholder {
	color: $text-color-secondary;
}

.p-input-filled .p-inputtext {
	background-color: $background-color;
}
.p-input-filled .p-inputtext:enabled:hover {
	background-color: $background-color;
}
.p-input-filled .p-inputtext:enabled:focus {
	background-color: $background-color;
}

.p-inputtext-sm .p-inputtext {
	font-size: 0.875rem;
	padding: 0.4375rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
	font-size: 1.25rem;
	padding: 0.625rem 0.9375rem;
}

.p-listbox {
	background: #ffffff;
	color: $text-color;
	border: 1px solid #ced4da;
	border-radius: 4px;
}
.p-listbox .p-listbox-header {
	padding: 0.75rem 1.5rem;
	border-bottom: 1px solid $components-border-color;
	color: $text-color;
	background: $background-color;
	margin: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
	padding-right: 1.75rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
	right: 0.75rem;
	color: #495057;
}
.p-listbox .p-listbox-list {
	padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
	margin: 0;
	padding: 0.5rem 1.5rem;
	border: 0 none;
	color: $text-color;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
	color: #ffffff;
	background: $primary-color;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
	margin: 0;
	padding: 0.75rem 1rem;
	color: $text-color;
	background: #ffffff;
	font-weight: 600;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
	padding: 0.5rem 1.5rem;
	color: $text-color;
	background: transparent;
}
.p-listbox:not(.p-disabled)
	.p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
	color: $text-color;
	background: #e9ecef;
}
.p-listbox.p-invalid {
	border-color: $error-color;
}

.p-multiselect {
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
	border-color: #ced4da;
}
.p-multiselect:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
	padding-right: 1.75rem;
}
.p-multiselect .p-multiselect-label {
	padding: 0.5rem 0.75rem;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
	color: $text-color-secondary;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
	padding: 0.25rem 0.75rem;
	margin-right: 0.5rem;
	background: $primary-color;
	color: #ffffff;
	border-radius: 4px;
}
.p-multiselect.p-multiselect-chip
	.p-multiselect-token
	.p-multiselect-token-icon {
	margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
	background: transparent;
	color: #495057;
	width: 2.357rem;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.p-multiselect .p-multiselect-clear-icon {
	color: #495057;
	right: 2.357rem;
}
.p-multiselect.p-invalid.p-component {
	border-color: $error-color;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
	padding: 0.25rem 0.75rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable
	.p-multiselect-label {
	padding-right: 1.75rem;
}

.p-multiselect-panel {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: none;
}
.p-multiselect-panel .p-multiselect-header {
	padding: 0.75rem 1.5rem;
	border-bottom: 1px solid $components-border-color;
	color: $text-color;
	background: $background-color;
	margin: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-multiselect-panel
	.p-multiselect-header
	.p-multiselect-filter-container
	.p-inputtext {
	padding-right: 1.75rem;
}
.p-multiselect-panel
	.p-multiselect-header
	.p-multiselect-filter-container
	.p-multiselect-filter-icon {
	right: 0.75rem;
	color: #495057;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
	margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
	margin-left: 0.5rem;
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-multiselect-panel .p-multiselect-items {
	padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
	margin: 0;
	padding: 0.5rem 1.5rem;
	border: 0 none;
	color: $text-color;
	background: transparent;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	color: #ffffff;
	background: $primary-color;
}
.p-multiselect-panel
	.p-multiselect-items
	.p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
	color: $text-color;
	background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
	margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
	margin: 0;
	padding: 0.75rem 1rem;
	color: $text-color;
	background: #ffffff;
	font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
	padding: 0.5rem 1.5rem;
	color: $text-color;
	background: transparent;
}

.p-input-filled .p-multiselect {
	background: $background-color;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
	background-color: $background-color;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
	background-color: $background-color;
}
.p-password.p-component {
	input::placeholder {
		color: #929b9f;
	}
}

.p-password.p-invalid.p-component > .p-inputtext {
	border-color: $error-color;
}

.p-password-panel {
	padding: 1.25rem;
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: none;
	border-radius: 4px;
}
.p-password-panel .p-password-meter {
	margin-bottom: 0.5rem;
	background: #e9ecef;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
	background: $error-color;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
	background: #ffc107;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
	background: #28a745;
}

.p-radiobutton {
	width: 20px;
	height: 20px;
}
.p-radiobutton .p-radiobutton-box {
	border: 2px solid #ced4da;
	background: #ffffff;
	width: 20px;
	height: 20px;
	color: $text-color;
	border-radius: 50%;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
	border-color: #ced4da;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
	width: 12px;
	height: 12px;
	transition-duration: 0.15s;
	background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
	border-color: $primary-color;
	background: $primary-color;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
	border-color: $border-color;
	background: $border-color;
	color: #ffffff;
}
.p-radiobutton.p-invalid > .p-radiobutton-box {
	border-color: $error-color;
}
.p-radiobutton:focus {
	outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
	background-color: $background-color;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
	background-color: $background-color;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
	background: $primary-color;
}
.p-input-filled
	.p-radiobutton
	.p-radiobutton-box.p-highlight:not(.p-disabled):hover {
	background: $border-color;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
	border-color: #ffffff;
}

.p-rating .p-rating-icon {
	color: #495057;
	margin-left: 0.5rem;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
	color: $error-color;
}
.p-rating .p-rating-icon:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-rating .p-rating-icon:first-child {
	margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
	color: $primary-color;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
	color: $primary-color;
}
.p-rating:not(.p-disabled):not(.p-readonly)
	.p-rating-icon.p-rating-cancel:hover {
	color: $error-color;
}

.p-highlight .p-rating .p-rating-icon {
	color: #ffffff;
}

.p-selectbutton .p-button {
	background: $text-color-secondary;
	border: 1px solid $text-color-secondary;
	color: #ffffff;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
	color: #ffffff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
	background: #5a6268;
	border-color: #545b62;
	color: #ffffff;
}
.p-selectbutton
	.p-button:not(.p-disabled):not(.p-highlight):hover
	.p-button-icon-left,
.p-selectbutton
	.p-button:not(.p-disabled):not(.p-highlight):hover
	.p-button-icon-right {
	color: #ffffff;
}
.p-selectbutton .p-button.p-highlight {
	background: #545b62;
	border-color: #4e555b;
	color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
	color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
	background: #545b62;
	border-color: #4e555b;
	color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
	color: #ffffff;
}
.p-selectbutton.p-invalid > .p-button {
	border-color: $error-color;
}

.p-slider {
	background: #e9ecef;
	border: 0 none;
	border-radius: 4px;
}
.p-slider.p-slider-horizontal {
	height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
	margin-top: -0.5715rem;
	margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
	width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
	margin-left: -0.5715rem;
	margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
	height: 1.143rem;
	width: 1.143rem;
	background: $primary-color;
	border: 2px solid $primary-color;
	border-radius: 4px;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-slider .p-slider-handle:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-slider .p-slider-range {
	background: $primary-color;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
	background: $hover-color;
	border-color: $hover-color;
}

.p-treeselect {
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	border-radius: 4px;
}
.p-treeselect:not(.p-disabled):hover {
	border-color: #ced4da;
}
.p-treeselect:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: $primary-color;
}
.p-treeselect .p-treeselect-label {
	padding: 0.5rem 0.75rem;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
	color: $text-color-secondary;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
	padding: 0.25rem 0.75rem;
	margin-right: 0.5rem;
	background: $components-border-color;
	color: $text-color;
	border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
	background: transparent;
	color: #495057;
	width: 2.357rem;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.p-treeselect.p-invalid.p-component {
	border-color: $error-color;
}

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
	padding: 0.25rem 0.75rem;
}

.p-treeselect-panel {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: none;
}
.p-treeselect-panel .p-treeselect-header {
	padding: 0.75rem 1.5rem;
	border-bottom: 1px solid $components-border-color;
	color: $text-color;
	background: $background-color;
	margin: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
	margin-right: 0.5rem;
}
.p-treeselect-panel
	.p-treeselect-header
	.p-treeselect-filter-container
	.p-treeselect-filter {
	padding-right: 1.75rem;
}
.p-treeselect-panel
	.p-treeselect-header
	.p-treeselect-filter-container
	.p-treeselect-filter-icon {
	right: 0.75rem;
	color: #495057;
}
.p-treeselect-panel
	.p-treeselect-header
	.p-treeselect-filter-container.p-treeselect-clearable-filter
	.p-treeselect-filter {
	padding-right: 3.5rem;
}
.p-treeselect-panel
	.p-treeselect-header
	.p-treeselect-filter-container.p-treeselect-clearable-filter
	.p-treeselect-filter-clear-icon {
	right: 2.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
	border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
	padding: 0.5rem 1.5rem;
	color: $text-color;
	background: transparent;
}

.p-input-filled .p-treeselect {
	background: $background-color;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
	background-color: $background-color;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
	background-color: $background-color;
}

.p-togglebutton.p-button {
	background: $text-color-secondary;
	border: 1px solid $text-color-secondary;
	color: #ffffff;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
	color: #ffffff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
	background: #5a6268;
	border-color: #545b62;
	color: #ffffff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
	.p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
	.p-button-icon-right {
	color: #ffffff;
}
.p-togglebutton.p-button.p-highlight {
	background: #545b62;
	border-color: #4e555b;
	color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
	color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
	background: #545b62;
	border-color: #4e555b;
	color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
	color: #ffffff;
}
.p-togglebutton.p-button.p-invalid > .p-button {
	border-color: $error-color;
}

.p-button {
	color: #ffffff;
	background: $secondary-color;
	border: 1px solid $secondary-color;
	padding: 0.75rem;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.04em;
	font-weight: 700;
	font-family: Roboto;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	border-radius: 5px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	height: 45px;
}
.p-buttonbutton:enabled:hover {
	background: $hover-color;
	color: #ffffff;
	border-color: $hover-color;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-buttonbutton:enabled:active {
	background: $border-color;
	color: #ffffff;
	border-color: $border-color;
}
.p-button:not(button):not(.p-disabled):hover {
	background: $hover-color;
	color: #ffffff;
	border-color: $hover-color;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button:not(button):not(.p-disabled):active {
	background: $border-color;
	color: #ffffff;
	border-color: $border-color;
}
.p-buttona:enabled:hover {
	background: $hover-color;
	color: #ffffff;
	border-color: $hover-color;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-buttona:enabled:active {
	background: $border-color;
	color: #ffffff;
	border-color: $border-color;
}
.p-button:not(a):not(.p-disabled):hover {
	background: $hover-color;
	color: #ffffff;
	border-color: $hover-color;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button:not(a):not(.p-disabled):active {
	background: $boton-color-pressed;
	color: #ffffff;
	border-color: $boton-color-pressed;
}
.p-button.p-button-outlined {
	background-color: transparent;
	color: $primary-color;
	border: 1px solid;
}
.p-button.p-button-outlinedbutton:enabled:hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border: 1px solid;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button.p-button-outlinedbutton:enabled:active {
	background: $boton-hover-active;
	color: $primary-color;
	border: 1px solid;
}
.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border: 1px solid;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button.p-button-outlined:not(button):not(.p-disabled):active {
	background: $boton-hover-active;
	color: $primary-color;
	border: 1px solid;
}
.p-button.p-button-outlineda:enabled:hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border: 1px solid;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button.p-button-outlineda:enabled:active {
	background: $boton-hover-active;
	color: $primary-color;
	border: 1px solid;
}
.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border: 1px solid;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button.p-button-outlined:not(a):not(.p-disabled):active {
	background: $boton-hover-active;
	color: $primary-color;
	border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
	color: $text-color-secondary;
	border-color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plainbutton:enabled:hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plainbutton:enabled:active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plain:not(button):not(.p-disabled):hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plain:not(button):not(.p-disabled):active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plaina:enabled:hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plaina:enabled:active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plain:not(a):not(.p-disabled):hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-outlined.p-button-plain:not(a):not(.p-disabled):active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button.p-button-text {
	background-color: transparent;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-textbutton:enabled:hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-textbutton:enabled:active {
	background: $boton-hover-active;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-text:not(button):not(.p-disabled):hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-text:not(button):not(.p-disabled):active {
	background: $boton-hover-active;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-texta:enabled:hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-texta:enabled:active {
	background: $boton-hover-active;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-text:not(a):not(.p-disabled):hover {
	background: rgba(136, 60, 174, 0.04);
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-text:not(a):not(.p-disabled):active {
	background: $boton-hover-active;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plainbutton:enabled:hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plainbutton:enabled:active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plain:not(button):not(.p-disabled):hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plain:not(button):not(.p-disabled):active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plaina:enabled:hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plaina:enabled:active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plain:not(a):not(.p-disabled):hover {
	background: #e9ecef;
	color: $text-color-secondary;
}
.p-button.p-button-text.p-button-plain:not(a):not(.p-disabled):active {
	background: $components-border-color;
	color: $text-color-secondary;
}
.p-button:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: none;
}
.p-button .p-button-icon-left {
	color: #ffffff;
	margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
	margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
	margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
	margin-bottom: 0.5rem;
}
.p-button .p-badge {
	margin-left: 0.5rem;
	min-width: 1rem;
	height: 1rem;
	line-height: 1rem;
	color: $primary-color;
	background-color: #ffffff;
}
.p-button.p-button-raised {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
	border-radius: 2rem;
}
.p-button.p-button-icon-only {
	width: 2.357rem;
	padding: 0.5rem 0;
}
.p-button.p-button-icon-only:hover {
	box-shadow: none !important;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
	margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
	border-radius: 50%;
	height: 2.357rem;
}
.p-button.p-button-sm {
	font-size: 0.875rem;
	padding: 0.4375rem 0.65625rem;
}
.p-button.p-button-sm .p-button-icon {
	font-size: 0.875rem;
}
.p-button.p-button-lg {
	font-size: 1.25rem;
	padding: 0.625rem 0.9375rem;
}
.p-button.p-button-lg .p-button-icon {
	font-size: 1.25rem;
}
.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
	margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
	margin-right: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
	margin-top: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
	margin-bottom: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
	margin: 0;
}

.p-fluid .p-button {
	width: 100%;
}
.p-fluid .p-button-icon-only {
	width: 2.357rem;
}
.p-fluid .p-buttonset {
	display: flex;
}
.p-fluid .p-buttonset .p-button {
	flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-fileupload-choose.p-button-secondary {
	color: #ffffff;
	background: $boton-color-secondary;
	border: 1px solid $boton-color-secondary;
}
.p-button.p-button-secondarybutton:enabled:hover,
.p-buttonset.p-button-secondary > .p-buttonbutton:enabled:hover,
.p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-secondarybutton:enabled:hover {
	background: #5a6268;
	color: #ffffff;
	border-color: #5a6268;
}
.p-button.p-button-secondarybutton:enabled:focus,
.p-buttonset.p-button-secondary > .p-buttonbutton:enabled:focus,
.p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-secondarybutton:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.p-button.p-button-secondarybutton:enabled:active,
.p-buttonset.p-button-secondary > .p-buttonbutton:enabled:active,
.p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-secondarybutton:enabled:active {
	background: #545b62;
	color: #ffffff;
	border-color: #4e555b;
}
.p-button.p-button-secondary:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary
	> .p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):hover {
	background: #5a6268;
	color: #ffffff;
	border-color: #5a6268;
}
.p-button.p-button-secondary:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary
	> .p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.p-button.p-button-secondary:not(button):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-secondary
	> .p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):active {
	background: #545b62;
	color: #ffffff;
	border-color: #4e555b;
}
.p-button.p-button-secondarya:enabled:hover,
.p-buttonset.p-button-secondary > .p-buttona:enabled:hover,
.p-splitbutton.p-button-secondary > .p-buttona:enabled:hover,
.p-fileupload-choose.p-button-secondarya:enabled:hover {
	background: #5a6268;
	color: #ffffff;
	border-color: #5a6268;
}
.p-button.p-button-secondarya:enabled:focus,
.p-buttonset.p-button-secondary > .p-buttona:enabled:focus,
.p-splitbutton.p-button-secondary > .p-buttona:enabled:focus,
.p-fileupload-choose.p-button-secondarya:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.p-button.p-button-secondarya:enabled:active,
.p-buttonset.p-button-secondary > .p-buttona:enabled:active,
.p-splitbutton.p-button-secondary > .p-buttona:enabled:active,
.p-fileupload-choose.p-button-secondarya:enabled:active {
	background: #545b62;
	color: #ffffff;
	border-color: #4e555b;
}
.p-button.p-button-secondary:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):hover {
	background: $boton-color-secondary;
	color: #ffffff;
	border-color: $boton-color-secondary;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button.p-button-secondary:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):focus {
	box-shadow: none;
}
.p-button.p-button-secondary:not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):active {
	background: #545b62;
	color: #ffffff;
	border-color: #4e555b;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined {
	background-color: transparent;
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-secondary
	> .p-button.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:hover {
	background: rgba(108, 117, 125, 0.04);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-secondary
	> .p-button.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:active {
	background: rgba(108, 117, 125, 0.16);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-secondary
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover {
	background: rgba(108, 117, 125, 0.04);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-secondary
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active {
	background: rgba(108, 117, 125, 0.16);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:hover {
	background: rgba(108, 117, 125, 0.04);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlineda:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:active {
	background: rgba(108, 117, 125, 0.16);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover {
	background: rgba(108, 117, 125, 0.04);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active {
	background: rgba(108, 117, 125, 0.16);
	color: $text-color-secondary;
	border: 1px solid;
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text {
	background-color: transparent;
	color: $text-color-secondary;
	border-color: transparent;
}
.p-button.p-button-secondary.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:hover {
	background: rgba(108, 117, 125, 0.04);
	border-color: transparent;
	color: $text-color-secondary;
}
.p-button.p-button-secondary.p-button-textbutton:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:active {
	background: rgba(108, 117, 125, 0.16);
	border-color: transparent;
	color: $text-color-secondary;
}
.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-secondary
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover {
	background: rgba(108, 117, 125, 0.04);
	border-color: transparent;
	color: $text-color-secondary;
}
.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-secondary
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):active {
	background: rgba(108, 117, 125, 0.16);
	border-color: transparent;
	color: $text-color-secondary;
}
.p-button.p-button-secondary.p-button-texta:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-texta:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-texta:enabled:hover {
	background: rgba(108, 117, 125, 0.04);
	border-color: transparent;
	color: $text-color-secondary;
}
.p-button.p-button-secondary.p-button-texta:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-texta:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-texta:enabled:active {
	background: rgba(108, 117, 125, 0.16);
	border-color: transparent;
	color: $text-color-secondary;
}
.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover {
	background: rgba(108, 117, 125, 0.04);
	border-color: transparent;
	color: $text-color-secondary;
}
.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):active {
	background: rgba(108, 117, 125, 0.16);
	border-color: transparent;
	color: $text-color-secondary;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button,
.p-fileupload-choose.p-button-info {
	color: #ffffff;
	background: #17a2b8;
	border: 1px solid #17a2b8;
}
.p-button.p-button-infobutton:enabled:hover,
.p-buttonset.p-button-info > .p-buttonbutton:enabled:hover,
.p-splitbutton.p-button-info > .p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-infobutton:enabled:hover {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-infobutton:enabled:focus,
.p-buttonset.p-button-info > .p-buttonbutton:enabled:focus,
.p-splitbutton.p-button-info > .p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-infobutton:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.p-button.p-button-infobutton:enabled:active,
.p-buttonset.p-button-info > .p-buttonbutton:enabled:active,
.p-splitbutton.p-button-info > .p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-infobutton:enabled:active {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-info:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-info > .p-button:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:not(button):not(.p-disabled):hover {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-info:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-info > .p-button:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-info > .p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:not(button):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.p-button.p-button-info:not(button):not(.p-disabled):active,
.p-buttonset.p-button-info > .p-button:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:not(button):not(.p-disabled):active {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-infoa:enabled:hover,
.p-buttonset.p-button-info > .p-buttona:enabled:hover,
.p-splitbutton.p-button-info > .p-buttona:enabled:hover,
.p-fileupload-choose.p-button-infoa:enabled:hover {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-infoa:enabled:focus,
.p-buttonset.p-button-info > .p-buttona:enabled:focus,
.p-splitbutton.p-button-info > .p-buttona:enabled:focus,
.p-fileupload-choose.p-button-infoa:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.p-button.p-button-infoa:enabled:active,
.p-buttonset.p-button-info > .p-buttona:enabled:active,
.p-splitbutton.p-button-info > .p-buttona:enabled:active,
.p-fileupload-choose.p-button-infoa:enabled:active {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-info:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:not(a):not(.p-disabled):hover {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-info:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-info > .p-button:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-info > .p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:not(a):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.p-button.p-button-info:not(a):not(.p-disabled):active,
.p-buttonset.p-button-info > .p-button:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:not(a):not(.p-disabled):active {
	background: #138496;
	color: #ffffff;
	border-color: #117a8b;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-info.p-button-outlined {
	background-color: transparent;
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlinedbutton:enabled:hover {
	background: rgba(23, 162, 184, 0.04);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlinedbutton:enabled:active {
	background: rgba(23, 162, 184, 0.16);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-info
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-info
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(.p-disabled):hover {
	background: rgba(23, 162, 184, 0.04);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-info
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-info
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(.p-disabled):active {
	background: rgba(23, 162, 184, 0.16);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlineda:enabled:hover {
	background: rgba(23, 162, 184, 0.04);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlineda:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlineda:enabled:active {
	background: rgba(23, 162, 184, 0.16);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(a):not(.p-disabled):hover {
	background: rgba(23, 162, 184, 0.04);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-info
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(a):not(.p-disabled):active {
	background: rgba(23, 162, 184, 0.16);
	color: #17a2b8;
	border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text,
.p-fileupload-choose.p-button-info.p-button-text {
	background-color: transparent;
	color: #17a2b8;
	border-color: transparent;
}
.p-button.p-button-info.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-textbutton:enabled:hover {
	background: rgba(23, 162, 184, 0.04);
	border-color: transparent;
	color: #17a2b8;
}
.p-button.p-button-info.p-button-textbutton:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-info.p-button-textbutton:enabled:active {
	background: rgba(23, 162, 184, 0.16);
	border-color: transparent;
	color: #17a2b8;
}
.p-button.p-button-info.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-info
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-info
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(.p-disabled):hover {
	background: rgba(23, 162, 184, 0.04);
	border-color: transparent;
	color: #17a2b8;
}
.p-button.p-button-info.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-info
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-info
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(.p-disabled):active {
	background: rgba(23, 162, 184, 0.16);
	border-color: transparent;
	color: #17a2b8;
}
.p-button.p-button-info.p-button-texta:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-texta:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-texta:enabled:hover {
	background: rgba(23, 162, 184, 0.04);
	border-color: transparent;
	color: #17a2b8;
}
.p-button.p-button-info.p-button-texta:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-texta:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-info.p-button-texta:enabled:active {
	background: rgba(23, 162, 184, 0.16);
	border-color: transparent;
	color: #17a2b8;
}
.p-button.p-button-info.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:not(a):not(.p-disabled):hover {
	background: rgba(23, 162, 184, 0.04);
	border-color: transparent;
	color: #17a2b8;
}
.p-button.p-button-info.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-info
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:not(a):not(.p-disabled):active {
	background: rgba(23, 162, 184, 0.16);
	border-color: transparent;
	color: #17a2b8;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button,
.p-fileupload-choose.p-button-success {
	color: #ffffff;
	background: #28a745;
	border: 1px solid #28a745;
}
.p-button.p-button-successbutton:enabled:hover,
.p-buttonset.p-button-success > .p-buttonbutton:enabled:hover,
.p-splitbutton.p-button-success > .p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-successbutton:enabled:hover {
	background: #218838;
	color: #ffffff;
	border-color: #1e7e34;
}
.p-button.p-button-successbutton:enabled:focus,
.p-buttonset.p-button-success > .p-buttonbutton:enabled:focus,
.p-splitbutton.p-button-success > .p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-successbutton:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.p-button.p-button-successbutton:enabled:active,
.p-buttonset.p-button-success > .p-buttonbutton:enabled:active,
.p-splitbutton.p-button-success > .p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-successbutton:enabled:active {
	background: #1e7e34;
	color: #ffffff;
	border-color: #1c7430;
}
.p-button.p-button-success:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:not(button):not(.p-disabled):hover {
	background: #218838;
	color: #ffffff;
	border-color: #1e7e34;
}
.p-button.p-button-success:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-success > .p-button:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-success > .p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:not(button):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.p-button.p-button-success:not(button):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:not(button):not(.p-disabled):active {
	background: #1e7e34;
	color: #ffffff;
	border-color: #1c7430;
}
.p-button.p-button-successa:enabled:hover,
.p-buttonset.p-button-success > .p-buttona:enabled:hover,
.p-splitbutton.p-button-success > .p-buttona:enabled:hover,
.p-fileupload-choose.p-button-successa:enabled:hover {
	background: #218838;
	color: #ffffff;
	border-color: #1e7e34;
}
.p-button.p-button-successa:enabled:focus,
.p-buttonset.p-button-success > .p-buttona:enabled:focus,
.p-splitbutton.p-button-success > .p-buttona:enabled:focus,
.p-fileupload-choose.p-button-successa:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.p-button.p-button-successa:enabled:active,
.p-buttonset.p-button-success > .p-buttona:enabled:active,
.p-splitbutton.p-button-success > .p-buttona:enabled:active,
.p-fileupload-choose.p-button-successa:enabled:active {
	background: #1e7e34;
	color: #ffffff;
	border-color: #1c7430;
}
.p-button.p-button-success:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:not(a):not(.p-disabled):hover {
	background: #218838;
	color: #ffffff;
	border-color: #1e7e34;
}
.p-button.p-button-success:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success > .p-button:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success > .p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:not(a):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.p-button.p-button-success:not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:not(a):not(.p-disabled):active {
	background: #1e7e34;
	color: #ffffff;
	border-color: #1c7430;
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined {
	background-color: transparent;
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-success
	> .p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlinedbutton:enabled:hover {
	background: rgba(40, 167, 69, 0.04);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-success
	> .p-button.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-success
	> .p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlinedbutton:enabled:active {
	background: rgba(40, 167, 69, 0.16);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-success
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-success
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(.p-disabled):hover {
	background: rgba(40, 167, 69, 0.04);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-success
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-success
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(.p-disabled):active {
	background: rgba(40, 167, 69, 0.16);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlineda:enabled:hover {
	background: rgba(40, 167, 69, 0.04);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlineda:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlineda:enabled:active {
	background: rgba(40, 167, 69, 0.16);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(a):not(.p-disabled):hover {
	background: rgba(40, 167, 69, 0.04);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-success
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(a):not(.p-disabled):active {
	background: rgba(40, 167, 69, 0.16);
	color: #28a745;
	border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text {
	background-color: transparent;
	color: #28a745;
	border-color: transparent;
}
.p-button.p-button-success.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-textbutton:enabled:hover {
	background: rgba(40, 167, 69, 0.04);
	border-color: transparent;
	color: #28a745;
}
.p-button.p-button-success.p-button-textbutton:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-success.p-button-textbutton:enabled:active {
	background: rgba(40, 167, 69, 0.16);
	border-color: transparent;
	color: #28a745;
}
.p-button.p-button-success.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-success
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-success
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(.p-disabled):hover {
	background: rgba(40, 167, 69, 0.04);
	border-color: transparent;
	color: #28a745;
}
.p-button.p-button-success.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-success
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-success
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(.p-disabled):active {
	background: rgba(40, 167, 69, 0.16);
	border-color: transparent;
	color: #28a745;
}
.p-button.p-button-success.p-button-texta:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-texta:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-texta:enabled:hover {
	background: rgba(40, 167, 69, 0.04);
	border-color: transparent;
	color: #28a745;
}
.p-button.p-button-success.p-button-texta:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-texta:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-success.p-button-texta:enabled:active {
	background: rgba(40, 167, 69, 0.16);
	border-color: transparent;
	color: #28a745;
}
.p-button.p-button-success.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:not(a):not(.p-disabled):hover {
	background: rgba(40, 167, 69, 0.04);
	border-color: transparent;
	color: #28a745;
}
.p-button.p-button-success.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-success
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:not(a):not(.p-disabled):active {
	background: rgba(40, 167, 69, 0.16);
	border-color: transparent;
	color: #28a745;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button,
.p-fileupload-choose.p-button-warning {
	color: $text-color;
	background: #ffc107;
	border: 1px solid #ffc107;
}
.p-button.p-button-warningbutton:enabled:hover,
.p-buttonset.p-button-warning > .p-buttonbutton:enabled:hover,
.p-splitbutton.p-button-warning > .p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-warningbutton:enabled:hover {
	background: #e0a800;
	color: $text-color;
	border-color: #d39e00;
}
.p-button.p-button-warningbutton:enabled:focus,
.p-buttonset.p-button-warning > .p-buttonbutton:enabled:focus,
.p-splitbutton.p-button-warning > .p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-warningbutton:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.p-button.p-button-warningbutton:enabled:active,
.p-buttonset.p-button-warning > .p-buttonbutton:enabled:active,
.p-splitbutton.p-button-warning > .p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-warningbutton:enabled:active {
	background: #d39e00;
	color: $text-color;
	border-color: #c69500;
}
.p-button.p-button-warning:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-warning > .p-button:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):hover {
	background: #e0a800;
	color: $text-color;
	border-color: #d39e00;
}
.p-button.p-button-warning:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-warning > .p-button:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-warning > .p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.p-button.p-button-warning:not(button):not(.p-disabled):active,
.p-buttonset.p-button-warning > .p-button:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):active {
	background: #d39e00;
	color: $text-color;
	border-color: #c69500;
}
.p-button.p-button-warninga:enabled:hover,
.p-buttonset.p-button-warning > .p-buttona:enabled:hover,
.p-splitbutton.p-button-warning > .p-buttona:enabled:hover,
.p-fileupload-choose.p-button-warninga:enabled:hover {
	background: #e0a800;
	color: $text-color;
	border-color: #d39e00;
}
.p-button.p-button-warninga:enabled:focus,
.p-buttonset.p-button-warning > .p-buttona:enabled:focus,
.p-splitbutton.p-button-warning > .p-buttona:enabled:focus,
.p-fileupload-choose.p-button-warninga:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.p-button.p-button-warninga:enabled:active,
.p-buttonset.p-button-warning > .p-buttona:enabled:active,
.p-splitbutton.p-button-warning > .p-buttona:enabled:active,
.p-fileupload-choose.p-button-warninga:enabled:active {
	background: #d39e00;
	color: $text-color;
	border-color: #c69500;
}
.p-button.p-button-warning:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):hover {
	background: #e0a800;
	color: $text-color;
	border-color: #d39e00;
}
.p-button.p-button-warning:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-warning > .p-button:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-warning > .p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.p-button.p-button-warning:not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning > .p-button:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):active {
	background: #d39e00;
	color: $text-color;
	border-color: #c69500;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-warning.p-button-outlined {
	background-color: transparent;
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-warning
	> .p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlinedbutton:enabled:hover {
	background: rgba(255, 193, 7, 0.04);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-warning
	> .p-button.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-warning
	> .p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlinedbutton:enabled:active {
	background: rgba(255, 193, 7, 0.16);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-warning
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-warning
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(.p-disabled):hover {
	background: rgba(255, 193, 7, 0.04);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-warning
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-warning
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(.p-disabled):active {
	background: rgba(255, 193, 7, 0.16);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlineda:enabled:hover {
	background: rgba(255, 193, 7, 0.04);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlineda:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlineda:enabled:active {
	background: rgba(255, 193, 7, 0.16);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(a):not(.p-disabled):hover {
	background: rgba(255, 193, 7, 0.04);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(a):not(.p-disabled):active {
	background: rgba(255, 193, 7, 0.16);
	color: #ffc107;
	border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text,
.p-fileupload-choose.p-button-warning.p-button-text {
	background-color: transparent;
	color: #ffc107;
	border-color: transparent;
}
.p-button.p-button-warning.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-textbutton:enabled:hover {
	background: rgba(255, 193, 7, 0.04);
	border-color: transparent;
	color: #ffc107;
}
.p-button.p-button-warning.p-button-textbutton:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-textbutton:enabled:active {
	background: rgba(255, 193, 7, 0.16);
	border-color: transparent;
	color: #ffc107;
}
.p-button.p-button-warning.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-warning
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-warning
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(.p-disabled):hover {
	background: rgba(255, 193, 7, 0.04);
	border-color: transparent;
	color: #ffc107;
}
.p-button.p-button-warning.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-warning
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-warning
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(.p-disabled):active {
	background: rgba(255, 193, 7, 0.16);
	border-color: transparent;
	color: #ffc107;
}
.p-button.p-button-warning.p-button-texta:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-texta:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-texta:enabled:hover {
	background: rgba(255, 193, 7, 0.04);
	border-color: transparent;
	color: #ffc107;
}
.p-button.p-button-warning.p-button-texta:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-texta:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-texta:enabled:active {
	background: rgba(255, 193, 7, 0.16);
	border-color: transparent;
	color: #ffc107;
}
.p-button.p-button-warning.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(a):not(.p-disabled):hover {
	background: rgba(255, 193, 7, 0.04);
	border-color: transparent;
	color: #ffc107;
}
.p-button.p-button-warning.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:not(a):not(.p-disabled):active {
	background: rgba(255, 193, 7, 0.16);
	border-color: transparent;
	color: #ffc107;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button,
.p-fileupload-choose.p-button-help {
	color: #ffffff;
	background: #6f42c1;
	border: 1px solid #6f42c1;
}
.p-button.p-button-helpbutton:enabled:hover,
.p-buttonset.p-button-help > .p-buttonbutton:enabled:hover,
.p-splitbutton.p-button-help > .p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-helpbutton:enabled:hover {
	background: #633bad;
	color: #ffffff;
	border-color: #58349a;
}
.p-button.p-button-helpbutton:enabled:focus,
.p-buttonset.p-button-help > .p-buttonbutton:enabled:focus,
.p-splitbutton.p-button-help > .p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-helpbutton:enabled:focus {
	box-shadow: 0 0 0 0.2rem #d3c6ec;
}
.p-button.p-button-helpbutton:enabled:active,
.p-buttonset.p-button-help > .p-buttonbutton:enabled:active,
.p-splitbutton.p-button-help > .p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-helpbutton:enabled:active {
	background: #58349a;
	color: #ffffff;
	border-color: #4d2e87;
}
.p-button.p-button-help:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-help > .p-button:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:not(button):not(.p-disabled):hover {
	background: #633bad;
	color: #ffffff;
	border-color: #58349a;
}
.p-button.p-button-help:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-help > .p-button:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-help > .p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:not(button):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem #d3c6ec;
}
.p-button.p-button-help:not(button):not(.p-disabled):active,
.p-buttonset.p-button-help > .p-button:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:not(button):not(.p-disabled):active {
	background: #58349a;
	color: #ffffff;
	border-color: #4d2e87;
}
.p-button.p-button-helpa:enabled:hover,
.p-buttonset.p-button-help > .p-buttona:enabled:hover,
.p-splitbutton.p-button-help > .p-buttona:enabled:hover,
.p-fileupload-choose.p-button-helpa:enabled:hover {
	background: #633bad;
	color: #ffffff;
	border-color: #58349a;
}
.p-button.p-button-helpa:enabled:focus,
.p-buttonset.p-button-help > .p-buttona:enabled:focus,
.p-splitbutton.p-button-help > .p-buttona:enabled:focus,
.p-fileupload-choose.p-button-helpa:enabled:focus {
	box-shadow: 0 0 0 0.2rem #d3c6ec;
}
.p-button.p-button-helpa:enabled:active,
.p-buttonset.p-button-help > .p-buttona:enabled:active,
.p-splitbutton.p-button-help > .p-buttona:enabled:active,
.p-fileupload-choose.p-button-helpa:enabled:active {
	background: #58349a;
	color: #ffffff;
	border-color: #4d2e87;
}
.p-button.p-button-help:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:not(a):not(.p-disabled):hover {
	background: #633bad;
	color: #ffffff;
	border-color: #58349a;
}
.p-button.p-button-help:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-help > .p-button:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-help > .p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:not(a):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem #d3c6ec;
}
.p-button.p-button-help:not(a):not(.p-disabled):active,
.p-buttonset.p-button-help > .p-button:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:not(a):not(.p-disabled):active {
	background: #58349a;
	color: #ffffff;
	border-color: #4d2e87;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-help.p-button-outlined {
	background-color: transparent;
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlinedbutton:enabled:hover {
	background: rgba(111, 66, 193, 0.04);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlinedbutton:enabled:active {
	background: rgba(111, 66, 193, 0.16);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-help
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-help
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(.p-disabled):hover {
	background: rgba(111, 66, 193, 0.04);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-help
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-help
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(.p-disabled):active {
	background: rgba(111, 66, 193, 0.16);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlineda:enabled:hover {
	background: rgba(111, 66, 193, 0.04);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlineda:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlineda:enabled:active {
	background: rgba(111, 66, 193, 0.16);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(a):not(.p-disabled):hover {
	background: rgba(111, 66, 193, 0.04);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-help
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(a):not(.p-disabled):active {
	background: rgba(111, 66, 193, 0.16);
	color: #6f42c1;
	border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text,
.p-fileupload-choose.p-button-help.p-button-text {
	background-color: transparent;
	color: #6f42c1;
	border-color: transparent;
}
.p-button.p-button-help.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-textbutton:enabled:hover {
	background: rgba(111, 66, 193, 0.04);
	border-color: transparent;
	color: #6f42c1;
}
.p-button.p-button-help.p-button-textbutton:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-help.p-button-textbutton:enabled:active {
	background: rgba(111, 66, 193, 0.16);
	border-color: transparent;
	color: #6f42c1;
}
.p-button.p-button-help.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-help
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-help
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(.p-disabled):hover {
	background: rgba(111, 66, 193, 0.04);
	border-color: transparent;
	color: #6f42c1;
}
.p-button.p-button-help.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-help
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-help
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(.p-disabled):active {
	background: rgba(111, 66, 193, 0.16);
	border-color: transparent;
	color: #6f42c1;
}
.p-button.p-button-help.p-button-texta:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-texta:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-texta:enabled:hover {
	background: rgba(111, 66, 193, 0.04);
	border-color: transparent;
	color: #6f42c1;
}
.p-button.p-button-help.p-button-texta:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-texta:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-help.p-button-texta:enabled:active {
	background: rgba(111, 66, 193, 0.16);
	border-color: transparent;
	color: #6f42c1;
}
.p-button.p-button-help.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:not(a):not(.p-disabled):hover {
	background: rgba(111, 66, 193, 0.04);
	border-color: transparent;
	color: #6f42c1;
}
.p-button.p-button-help.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-help
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:not(a):not(.p-disabled):active {
	background: rgba(111, 66, 193, 0.16);
	border-color: transparent;
	color: #6f42c1;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button,
.p-fileupload-choose.p-button-danger {
	color: #ffffff;
	background: $error-color;
	border: 1px solid $error-color;
}
.p-button.p-button-dangerbutton:enabled:hover,
.p-buttonset.p-button-danger > .p-buttonbutton:enabled:hover,
.p-splitbutton.p-button-danger > .p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-dangerbutton:enabled:hover {
	background: #c82333;
	color: #ffffff;
	border-color: #bd2130;
}
.p-button.p-button-dangerbutton:enabled:focus,
.p-buttonset.p-button-danger > .p-buttonbutton:enabled:focus,
.p-splitbutton.p-button-danger > .p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-dangerbutton:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.p-button.p-button-dangerbutton:enabled:active,
.p-buttonset.p-button-danger > .p-buttonbutton:enabled:active,
.p-splitbutton.p-button-danger > .p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-dangerbutton:enabled:active {
	background: #bd2130;
	color: #ffffff;
	border-color: #b21f2d;
}
.p-button.p-button-danger:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):hover {
	background: #c82333;
	color: #ffffff;
	border-color: #bd2130;
}
.p-button.p-button-danger:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-danger > .p-button:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-danger > .p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):focus {
	box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.p-button.p-button-danger:not(button):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):active {
	background: #bd2130;
	color: #ffffff;
	border-color: #b21f2d;
}
.p-button.p-button-dangera:enabled:hover,
.p-buttonset.p-button-danger > .p-buttona:enabled:hover,
.p-splitbutton.p-button-danger > .p-buttona:enabled:hover,
.p-fileupload-choose.p-button-dangera:enabled:hover {
	background: #c82333;
	color: #ffffff;
	border-color: #bd2130;
}
.p-button.p-button-dangera:enabled:focus,
.p-buttonset.p-button-danger > .p-buttona:enabled:focus,
.p-splitbutton.p-button-danger > .p-buttona:enabled:focus,
.p-fileupload-choose.p-button-dangera:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.p-button.p-button-dangera:enabled:active,
.p-buttonset.p-button-danger > .p-buttona:enabled:active,
.p-splitbutton.p-button-danger > .p-buttona:enabled:active,
.p-fileupload-choose.p-button-dangera:enabled:active {
	background: #bd2130;
	color: #ffffff;
	border-color: #b21f2d;
}
.p-button.p-button-danger:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):hover {
	background: $error-color;
	color: #ffffff;
	border-color: $error-color;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button.p-button-danger:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-danger > .p-button:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-danger > .p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):focus {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p-button.p-button-danger:not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):active {
	background: #bd2130;
	color: #ffffff;
	border-color: #b21f2d;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
	background-color: transparent;
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-danger
	> .p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlinedbutton:enabled:hover {
	background: rgba(220, 53, 69, 0.04);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-danger
	> .p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlinedbutton:enabled:active {
	background: rgba(220, 53, 69, 0.16);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-danger
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-danger
	> .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(.p-disabled):hover {
	background: rgba(220, 53, 69, 0.04);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-danger
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-danger
	> .p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(.p-disabled):active {
	background: rgba(220, 53, 69, 0.16);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlineda:enabled:hover {
	background: rgba(220, 53, 69, 0.04);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlineda:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlineda:enabled:active {
	background: rgba(220, 53, 69, 0.16);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger
	> .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover {
	background: rgba(220, 53, 69, 0.04);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger
	> .p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(a):not(.p-disabled):active {
	background: rgba(220, 53, 69, 0.16);
	color: $error-color;
	border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text {
	background-color: transparent;
	color: $error-color;
	border-color: transparent;
}
.p-button.p-button-danger.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-textbutton:enabled:hover {
	background: rgba(220, 53, 69, 0.04);
	border-color: transparent;
	color: $error-color;
}
.p-button.p-button-danger.p-button-textbutton:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-textbutton:enabled:active {
	background: rgba(220, 53, 69, 0.16);
	border-color: transparent;
	color: $error-color;
}
.p-button.p-button-danger.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-danger
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-danger
	> .p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(.p-disabled):hover {
	background: rgba(220, 53, 69, 0.04);
	border-color: transparent;
	color: $error-color;
}
.p-button.p-button-danger.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-danger
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-danger
	> .p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(.p-disabled):active {
	background: rgba(220, 53, 69, 0.16);
	border-color: transparent;
	color: $error-color;
}
.p-button.p-button-danger.p-button-texta:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-texta:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-texta:enabled:hover {
	background: rgba(220, 53, 69, 0.04);
	border-color: transparent;
	color: $error-color;
}
.p-button.p-button-danger.p-button-texta:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-texta:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-texta:enabled:active {
	background: rgba(220, 53, 69, 0.16);
	border-color: transparent;
	color: $error-color;
}
.p-button.p-button-danger.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger
	> .p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(a):not(.p-disabled):hover {
	background: rgba(220, 53, 69, 0.04);
	border-color: transparent;
	color: $error-color;
}
.p-button.p-button-danger.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger
	> .p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:not(a):not(.p-disabled):active {
	background: rgba(220, 53, 69, 0.16);
	border-color: transparent;
	color: $error-color;
}

.p-button.p-button-link {
	color: $primary-color;
	background: transparent;
	border: transparent;
}
.p-button.p-button-linkbutton:enabled:hover {
	background: transparent;
	color: $hover-color;
	border-color: transparent;
}
.p-button.p-button-linkbutton:enabled:hover .p-button-label {
	text-decoration: underline;
}
.p-button.p-button-linkbutton:enabled:focus {
	background: transparent;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: transparent;
}
.p-button.p-button-linkbutton:enabled:active {
	background: transparent;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-link:not(button):not(.p-disabled):hover {
	background: transparent;
	color: $hover-color;
	border-color: transparent;
}
.p-button.p-button-link:not(button):not(.p-disabled):hover .p-button-label {
	text-decoration: underline;
}
.p-button.p-button-link:not(button):not(.p-disabled):focus {
	background: transparent;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: transparent;
}
.p-button.p-button-link:not(button):not(.p-disabled):active {
	background: transparent;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-linka:enabled:hover {
	background: transparent;
	color: $hover-color;
	border-color: transparent;
}
.p-button.p-button-linka:enabled:hover .p-button-label {
	text-decoration: underline;
}
.p-button.p-button-linka:enabled:focus {
	background: transparent;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: transparent;
}
.p-button.p-button-linka:enabled:active {
	background: transparent;
	color: $primary-color;
	border-color: transparent;
}
.p-button.p-button-link:not(a):not(.p-disabled):hover {
	background: transparent;
	color: $hover-color;
	border-color: transparent;
}
.p-button.p-button-link:not(a):not(.p-disabled):hover .p-button-label {
	text-decoration: underline;
}
.p-button.p-button-link:not(a):not(.p-disabled):focus {
	background: transparent;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	border-color: transparent;
}
.p-button.p-button-link:not(a):not(.p-disabled):active {
	background: transparent;
	color: $primary-color;
	border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
	margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-carousel .p-carousel-indicators {
	padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
	background-color: #e9ecef;
	width: 2rem;
	height: 0.5rem;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
	background: $components-border-color;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
	background: $primary-color;
	color: #ffffff;
}
.p-datatable {
	display: flex;
	flex-direction: column;
	flex-grow: 2;
}
.p-datatable .p-paginator-top {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-datatable .p-paginator-bottom {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-datatable .p-datatable-header {
	background: $background-color;
	color: $text-color;
	border: solid $components-border-color;
	border-width: 1px 0 0 0;
	padding: 1rem 1rem;
	font-weight: 600;
}
.p-datatable .p-datatable-footer {
	background: $background-color;
	color: $text-color;
	border: 1px solid $components-border-color;
	border-width: 1px 0 1px 0;
	padding: 1rem 1rem;
	font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
	text-align: left;
	font-size: 18px;
	padding: 1rem 1rem;
	border-bottom: 1px solid $components-border-color-header;
	border-width: 1px 0 2px 0;
	font-weight: 700;
	color: $text-color;
	background: #ffffff;
	transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tfoot > tr > td {
	text-align: left;
	padding: 1rem 1rem;
	border: 1px solid $components-border-color;
	border-width: 1px 0 1px 0;
	font-weight: 600;
	color: $text-color;
	background: #ffffff;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
	color: $text-color-secondary;
	margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
	border-radius: 50%;
	height: 1.143rem;
	min-width: 1.143rem;
	line-height: 1.143rem;
	color: #ffffff;
	background: $primary-color;
	margin-left: 0.5rem;
}
.p-datatable
	.p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-datatable
	.p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover
	.p-sortable-column-icon {
	color: $text-color-secondary;
}
.p-datatable .p-sortable-column.p-highlight {
	background: #ffffff;
	color: $primary-color;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: $primary-color;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
	background: #e9ecef;
	color: $primary-color;
}
.p-datatable
	.p-sortable-column.p-highlight:not(.p-sortable-disabled):hover
	.p-sortable-column-icon {
	color: $primary-color;
}
.p-datatable .p-sortable-column:focus {
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
	outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
	background: #ffffff;
	color: $text-color;
	transition: box-shadow 0.15s;
	outline-color: $box-shadow;
}
.p-datatable .p-datatable-tbody > tr > td {
	text-align: left;
	border: 1px solid $components-border-color;
	border-width: 1px 0 0 0;
	padding: 1rem 1rem;
	outline-color: $box-shadow;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
	margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td.p-highlight {
	background: $primary-color;
	color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
	background: $primary-color;
	color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
	box-shadow: inset 0 2px 0 0 $primary-color;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
	box-shadow: inset 0 -2px 0 0 $primary-color;
}
.p-datatable.p-datatable-hoverable-rows
	.p-datatable-tbody
	> tr:not(.p-highlight):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-datatable .p-column-resizer-helper {
	background: $primary-color;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
	background: $background-color;
}
.p-datatable .p-datatable-loading-icon {
	font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
	border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
	border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
	border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
	border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
	border-width: 1px 1px 2px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
	border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
	border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
	background: rgba(0, 0, 0, 0.05);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
	background: $primary-color;
	color: #ffffff;
}
.p-datatable.p-datatable-striped
	.p-datatable-tbody
	> tr.p-row-odd.p-highlight
	.p-row-toggler {
	color: #ffffff;
}
.p-datatable.p-datatable-striped
	.p-datatable-tbody
	> tr.p-row-odd.p-highlight
	.p-row-toggler:hover {
	color: #ffffff;
}
.p-datatable.p-datatable-striped
	.p-datatable-tbody
	> tr.p-row-odd
	+ .p-row-expanded {
	background: rgba(0, 0, 0, 0.05);
}
.p-datatable.p-datatable-sm .p-datatable-header {
	padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
	padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
	padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
	padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
	padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
	padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
	padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
	padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
	padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
	padding: 1.25rem 1.25rem;
}

.p-datatable-drag-selection-helper {
	background: $boton-hover-active;
}

.p-datatable-column-button {
	color: $secondary-color;
	font-family: 'Roboto';
	font-size: 14px;
	line-height: 30px;
	font-weight: bold;
	background: none;
	border: none;
	cursor: pointer;
}

.p-dataview .p-paginator-top {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-dataview .p-paginator-bottom {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-dataview .p-dataview-header {
	background: $background-color;
	color: $text-color;
	border: solid $components-border-color;
	border-width: 1px 0 0 0;
	padding: 1rem 1rem;
	font-weight: 600;
}
.p-dataview .p-dataview-content {
	background: #ffffff;
	color: $text-color;
	border: 0 none;
	padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
	border: 1px solid $components-border-color;
	border-width: 1px 0 0 0;
}
.p-dataview .p-dataview-footer {
	background: $background-color;
	color: $text-color;
	border: 1px solid $components-border-color;
	border-width: 1px 0 1px 0;
	padding: 1rem 1rem;
	font-weight: 600;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.p-dataview .p-dataview-loading-icon {
	font-size: 2rem;
}

.p-datascroller .p-paginator-top {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-datascroller .p-paginator-bottom {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-datascroller .p-datascroller-header {
	background: $background-color;
	color: $text-color;
	border: solid $components-border-color;
	border-width: 1px 0 0 0;
	padding: 1rem 1rem;
	font-weight: 600;
}
.p-datascroller .p-datascroller-content {
	background: #ffffff;
	color: $text-color;
	border: 0 none;
	padding: 0;
}
.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
	border: 1px solid $components-border-color;
	border-width: 1px 0 0 0;
}
.p-datascroller .p-datascroller-footer {
	background: $background-color;
	color: $text-color;
	border: 1px solid $components-border-color;
	border-width: 1px 0 1px 0;
	padding: 1rem 1rem;
	font-weight: 600;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.fc .fc-view-container th {
	background: $background-color;
	border: 1px solid $components-border-color;
	color: $text-color;
}
.fc .fc-view-container td.fc-widget-content {
	border: 1px solid $components-border-color;
	color: $text-color;
}
.fc .fc-view-container td.fc-head-container {
	border: 1px solid $components-border-color;
}
.fc .fc-view-container .fc-view {
	background: #ffffff;
}
.fc .fc-view-container .fc-row {
	border-right: 1px solid $components-border-color;
}
.fc .fc-view-container .fc-event {
	background: $hover-color;
	border: 1px solid $hover-color;
	color: #ffffff;
}
.fc .fc-view-container .fc-divider {
	background: $background-color;
	border: 1px solid $components-border-color;
}
.fc .fc-toolbar .fc-button {
	color: #ffffff;
	background: $primary-color;
	border: 1px solid $primary-color;
	font-size: 1rem;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
	border-radius: 4px;
	display: flex;
	align-items: center;
}
.fc .fc-toolbar .fc-button:enabled:hover {
	background: $hover-color;
	color: #ffffff;
	border-color: $hover-color;
}
.fc .fc-toolbar .fc-button:enabled:active {
	background: $border-color;
	color: #ffffff;
	border-color: $border-color;
}
.fc .fc-toolbar .fc-button:enabled:active:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
	font-family: 'PrimeIcons' !important;
	text-indent: 0;
	font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
	content: '';
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
	font-family: 'PrimeIcons' !important;
	text-indent: 0;
	font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
	content: '';
}
.fc .fc-toolbar .fc-button:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button {
	background: $text-color-secondary;
	border: 1px solid $text-color-secondary;
	color: #ffffff;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
	background: #5a6268;
	border-color: #545b62;
	color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
	background: #545b62;
	border-color: #4e555b;
	color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
	background: #545b62;
	border-color: #4e555b;
	color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
	z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
	border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.p-orderlist .p-orderlist-controls {
	padding: 1.25rem;
}
.p-orderlist .p-orderlist-controls .p-button {
	margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
	background: $background-color;
	color: $text-color;
	border: 1px solid $components-border-color;
	padding: 1rem 1.25rem;
	font-weight: 600;
	border-bottom: 0 none;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-orderlist .p-orderlist-list {
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	padding: 0.5rem 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
	padding: 0.5rem 1.5rem;
	margin: 0;
	border: 0 none;
	color: $text-color;
	background: transparent;
	transition: transform 0.15s, box-shadow 0.15s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
	color: #ffffff;
	background: $primary-color;
}

@media screen and (max-width: 769px) {
	.p-orderlist {
		flex-direction: column;
	}
	.p-orderlist .p-orderlist-controls {
		padding: 1.25rem;
		flex-direction: row;
	}
	.p-orderlist .p-orderlist-controls .p-button {
		margin-right: 0.5rem;
		margin-bottom: 0;
	}
	.p-orderlist .p-orderlist-controls .p-button:last-child {
		margin-right: 0;
	}
}
.p-organizationchart
	.p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
	background: $primary-color;
	color: #ffffff;
}
.p-organizationchart
	.p-organizationchart-node-content.p-highlight
	.p-node-toggler
	i {
	color: #3e1b4f;
}
.p-organizationchart .p-organizationchart-line-down {
	background: $components-border-color;
}
.p-organizationchart .p-organizationchart-line-left {
	border-right: 1px solid $components-border-color;
	border-color: $components-border-color;
}
.p-organizationchart .p-organizationchart-line-top {
	border-top: 1px solid $components-border-color;
	border-color: $components-border-color;
}
.p-organizationchart .p-organizationchart-node-content {
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	padding: 1.25rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
	background: inherit;
	color: inherit;
	border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}

.p-paginator {
	background: #ffffff;
	color: $secondary-color;
	border-width: 0;
	padding: 0.75rem;
	border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
	background-color: #ffffff;
	color: $secondary-color;
	min-width: 2.357rem;
	height: 2.357rem;
	margin: 0 0 0 -1px;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
	background: #e9ecef;
	border-color: $components-border-color;
	color: $hover-color;
}
.p-paginator .p-paginator-first {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-paginator .p-paginator-last {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.p-paginator .p-dropdown {
	margin-left: 0.5rem;
	height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
	padding-right: 0;
}
.p-paginator .p-paginator-current {
	background-color: #ffffff;
	color: $secondary-color;
	min-width: 2.357rem;
	height: 2.357rem;
	margin: 0 0 0 -1px;
	padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
	background-color: #ffffff;
	color: $secondary-color;
	min-width: 2.357rem;
	height: 2.357rem;
	margin: 0 0 0 -1px;
	transition: box-shadow 0.15s;
	border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	background: $primary-color;
	color: $secondary-color;
	font-weight: bold;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
	background: #e9ecef;
	border-color: blue;
	color: $hover-color;
}

.p-picklist .p-picklist-buttons {
	padding: 1.25rem;
}
.p-picklist .p-picklist-buttons .p-button {
	margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
	background: $background-color;
	color: $text-color;
	border: 1px solid $components-border-color;
	padding: 1rem 1.25rem;
	font-weight: 600;
	border-bottom: 0 none;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-picklist .p-picklist-list {
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	padding: 0.5rem 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-picklist .p-picklist-list .p-picklist-item {
	padding: 0.5rem 1.5rem;
	margin: 0;
	border: 0 none;
	color: $text-color;
	background: transparent;
	transition: transform 0.15s, box-shadow 0.15s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
	color: #ffffff;
	background: $primary-color;
}

@media screen and (max-width: 895px) {
	.p-container {
		padding: 40px 2em 0px 2em;
	}
	.p-picklist {
		flex-direction: column;
	}
	.p-picklist .p-picklist-buttons {
		padding: 1.25rem;
		flex-direction: row;
	}
	.p-picklist .p-picklist-buttons .p-button {
		margin-right: 0.5rem;
		margin-bottom: 0;
	}
	.p-picklist .p-picklist-buttons .p-button:last-child {
		margin-right: 0;
	}
	.p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
		content: '';
	}
	.p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
		content: '';
	}
	.p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
		content: '';
	}
	.p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
		content: '';
	}
}
.p-tree {
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	padding: 1.25rem;
	border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode {
	padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
	border-radius: 4px;
	transition: box-shadow 0.15s;
	padding: 0.286rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
	margin-right: 0.5rem;
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content
	.p-tree-toggler:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content
	.p-tree-toggler:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
	margin-right: 0.5rem;
	color: $text-color-secondary;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
	margin-right: 0.5rem;
}
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content
	.p-checkbox
	.p-indeterminate
	.p-checkbox-icon {
	color: $text-color;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
	background: $primary-color;
	color: #ffffff;
}
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content.p-highlight
	.p-tree-toggler,
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content.p-highlight
	.p-treenode-icon {
	color: #ffffff;
}
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content.p-highlight
	.p-tree-toggler:hover,
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content.p-highlight
	.p-treenode-icon:hover {
	color: #ffffff;
}
.p-tree
	.p-tree-container
	.p-treenode
	.p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-tree .p-tree-filter-container {
	margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
	width: 100%;
	padding-right: 1.75rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
	right: 0.75rem;
	color: #495057;
}
.p-tree .p-treenode-children {
	padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
	font-size: 2rem;
}

.p-treetable .p-paginator-top {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-treetable .p-paginator-bottom {
	border-width: 1px 0 0 0;
	border-radius: 0;
}
.p-treetable .p-treetable-header {
	background: $background-color;
	color: $text-color;
	border: solid $components-border-color;
	border-width: 1px 0 0 0;
	padding: 1rem 1rem;
	font-weight: 600;
}
.p-treetable .p-treetable-footer {
	background: $background-color;
	color: $text-color;
	border: 1px solid $components-border-color;
	border-width: 1px 0 1px 0;
	padding: 1rem 1rem;
	font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
	text-align: left;
	padding: 1rem 1rem;
	border: 1px solid $components-border-color;
	border-width: 1px 0 2px 0;
	font-weight: 600;
	color: $text-color;
	background: #ffffff;
	transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tfoot > tr > td {
	text-align: left;
	padding: 1rem 1rem;
	border: 1px solid $components-border-color;
	border-width: 1px 0 1px 0;
	font-weight: 600;
	color: $text-color;
	background: #ffffff;
}
.p-treetable .p-sortable-column {
	outline-color: $box-shadow;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
	color: $text-color-secondary;
	margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
	border-radius: 50%;
	height: 1.143rem;
	min-width: 1.143rem;
	line-height: 1.143rem;
	color: #ffffff;
	background: $primary-color;
	margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-treetable
	.p-sortable-column:not(.p-highlight):hover
	.p-sortable-column-icon {
	color: $text-color-secondary;
}
.p-treetable .p-sortable-column.p-highlight {
	background: #ffffff;
	color: $primary-color;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: $primary-color;
}
.p-treetable .p-treetable-tbody > tr {
	background: #ffffff;
	color: $text-color;
	transition: box-shadow 0.15s;
	outline-color: $box-shadow;
}
.p-treetable .p-treetable-tbody > tr > td {
	text-align: left;
	border: 1px solid $components-border-color;
	border-width: 1px 0 0 0;
	padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
	margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
	margin-right: 0.5rem;
}
.p-treetable
	.p-treetable-tbody
	> tr
	> td
	.p-treetable-toggler
	+ .p-checkbox
	.p-indeterminate
	.p-checkbox-icon {
	color: $text-color;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
	background: $primary-color;
	color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
	color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
	color: #ffffff;
}
.p-treetable.p-treetable-hoverable-rows
	.p-treetable-tbody
	> tr:not(.p-highlight):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-treetable.p-treetable-hoverable-rows
	.p-treetable-tbody
	> tr:not(.p-highlight):hover
	.p-treetable-toggler {
	color: $text-color;
}
.p-treetable .p-column-resizer-helper {
	background: $primary-color;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
	background: $background-color;
}
.p-treetable .p-treetable-loading-icon {
	font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
	border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
	border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
	border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
	border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
	border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
	border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
	border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
	padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
	padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
	padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
	padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
	padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
	padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
	padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
	padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
	padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
	padding: 1.25rem 1.25rem;
}

.p-timeline .p-timeline-event-marker {
	border: 0 none;
	border-radius: 50%;
	width: 1rem;
	height: 1rem;
	background-color: $primary-color;
}
.p-timeline .p-timeline-event-connector {
	background-color: $components-border-color;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
	padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
	width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
	padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
	height: 2px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
	padding: 1rem 1.25rem;
	border: 1px solid $components-border-color;
	color: $text-color;
	background: $background-color;
	font-weight: 600;
	border-radius: 4px;
	transition: box-shadow 0.15s;
}
.p-accordion
	.p-accordion-header
	.p-accordion-header-link
	.p-accordion-toggle-icon {
	margin-right: 0.5rem;
}
.p-accordion
	.p-accordion-header:not(.p-disabled)
	.p-accordion-header-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-accordion
	.p-accordion-header:not(.p-highlight):not(.p-disabled):hover
	.p-accordion-header-link {
	background: #e9ecef;
	border-color: $components-border-color;
	color: $text-color;
}
.p-accordion
	.p-accordion-header:not(.p-disabled).p-highlight
	.p-accordion-header-link {
	background: $background-color;
	border-color: $components-border-color;
	color: $text-color;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.p-accordion
	.p-accordion-header:not(.p-disabled).p-highlight:hover
	.p-accordion-header-link {
	border-color: $components-border-color;
	background: #e9ecef;
	color: $text-color;
}
.p-accordion .p-accordion-content {
	padding: 1.25rem;
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	border-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-accordion .p-accordion-tab {
	margin-bottom: 0;
}
.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
	border-radius: 0;
}
.p-accordion .p-accordion-tab .p-accordion-content {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.p-accordion
	.p-accordion-tab:not(:first-child)
	.p-accordion-header
	.p-accordion-header-link {
	border-top: 0 none;
}
.p-accordion
	.p-accordion-tab:not(:first-child)
	.p-accordion-header:not(.p-highlight):not(.p-disabled):hover
	.p-accordion-header-link,
.p-accordion
	.p-accordion-tab:not(:first-child)
	.p-accordion-header:not(.p-disabled).p-highlight:hover
	.p-accordion-header-link {
	border-top: 0 none;
}
.p-accordion
	.p-accordion-tab:first-child
	.p-accordion-header
	.p-accordion-header-link {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-accordion
	.p-accordion-tab:last-child
	.p-accordion-header:not(.p-highlight)
	.p-accordion-header-link {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-accordion
	.p-accordion-tab:last-child
	.p-accordion-header:not(.p-highlight)
	.p-accordion-content {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.p-card {
	background: #ffffff;
	color: $text-color;
	border-radius: 10px;
}
.p-card .p-card-body {
	padding: 2rem 3.5rem 1.5rem 3.5rem;
}
.p-card .p-card-title {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
	font-weight: 400;
	margin-bottom: 0.5rem;
	color: $text-color-secondary;
}
.p-card .p-card-footer {
	padding: 1rem 0 0 0;
}

.p-fieldset {
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	border-radius: 4px;
}
.p-fieldset .p-fieldset-legend {
	padding: 1rem 1.25rem;
	border: 1px solid $components-border-color;
	color: $text-color;
	background: $background-color;
	font-weight: 600;
	border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
	padding: 0;
	transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
	padding: 1rem 1.25rem;
	color: $text-color;
	border-radius: 4px;
	transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
	margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
	background: #e9ecef;
	border-color: $components-border-color;
	color: $text-color;
}
.p-fieldset .p-fieldset-content {
	padding: 1.25rem;
}

.p-divider .p-divider-content {
	background-color: #ffffff;
}
.p-divider.p-divider-horizontal {
	margin: 1rem 0;
	padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
	border-top: 1px $components-border-color;
}
.p-divider.p-divider-horizontal .p-divider-content {
	padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
	margin: 0 1rem;
	padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
	border-left: 1px $components-border-color;
}
.p-divider.p-divider-vertical .p-divider-content {
	padding: 0.5rem 0;
}

.p-panel .p-panel-header {
	border: 1px solid $components-border-color;
	padding: 1rem 1.25rem;
	background: $background-color;
	color: $text-color;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-panel .p-panel-header .p-panel-title {
	font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-panel.p-panel-toggleable .p-panel-header {
	padding: 0.5rem 1.25rem;
}
.p-panel .p-panel-content {
	padding: 1.25rem;
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-top: 0 none;
}
.p-panel .p-panel-footer {
	padding: 0.5rem 1.25rem;
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	border-top: 0 none;
}

.p-splitter {
	border: 1px solid $components-border-color;
	background: #ffffff;
	border-radius: 4px;
	color: $text-color;
}
.p-splitter .p-splitter-gutter {
	transition: box-shadow 0.15s;
	background: $background-color;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
	background: $components-border-color;
}
.p-splitter .p-splitter-gutter-resizing {
	background: $components-border-color;
}

.p-scrollpanel .p-scrollpanel-bar {
	background: $background-color;
	border: 0 none;
}

.p-tabview .p-tabview-nav {
	background: transparent;
	border: 1px solid $components-border-color;
	border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
	margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border: solid;
	border-width: 1px;
	border-color: #ffffff #ffffff $components-border-color #ffffff;
	background: #ffffff;
	color: $text-color-secondary;
	padding: 0.75rem 1rem;
	font-weight: 600;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	transition: box-shadow 0.15s;
	margin: 0 0 -1px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-tabview
	.p-tabview-nav
	li:not(.p-highlight):not(.p-disabled):hover
	.p-tabview-nav-link {
	background: #ffffff;
	border-color: $components-border-color;
	color: $text-color-secondary;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: #ffffff;
	border-color: $components-border-color $components-border-color #ffffff
		$components-border-color;
	color: #495057;
}
.p-tabview .p-tabview-panels {
	background: #ffffff;
	padding: 1.25rem;
	border: 0 none;
	color: $text-color;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.p-toolbar {
	background: $background-color;
	border: 1px solid $components-border-color;
	padding: 1rem 1.25rem;
	border-radius: 4px;
}
.p-toolbar .p-toolbar-separator {
	margin: 0 0.5rem;
}

.p-confirm-popup {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	box-shadow: none;
}
.p-confirm-popup .p-confirm-popup-content {
	padding: 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer {
	text-align: right;
	padding: 0 1.25rem 1.25rem 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
	margin: 0 0.5rem 0 0;
	width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
	margin: 0;
}
.p-confirm-popup:after {
	border: solid transparent;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
}
.p-confirm-popup:before {
	border: solid transparent;
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup.p-confirm-popup-flipped:after {
	border-top-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
	border-top-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup .p-confirm-popup-icon {
	font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
	margin-left: 1rem;
}

.p-dialog {
	border-radius: 4px;
	box-shadow: none;
	max-height: 90%;
}
.p-dialog .p-dialog-header {
	border-bottom: 1px solid #e9ecef;
	background: #ffffff;
	color: $text-color;
	padding: 1rem;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
	color: $title-dialog-color;
	margin-left: 10px;
	font-weight: 600;
	font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
	width: 2rem;
	height: 2rem;
	color: #9e9e9e;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
	margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
	margin-right: 0;
}
.p-dialog .p-dialog-content {
	padding-top: 45px;
	font-family: Lato;
	background: #ffffff;
	color: black;
	display: flex;
	justify-content: center;
}
.p-dialog .p-dialog-footer {
	background: #ffffff;
	color: $text-color;
	padding: 1rem;
	text-align: right;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
	margin: 0 0.5rem 0.5rem 0;
	width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
	font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
	margin-left: 1rem;
}
.p-dialog-mask {
	z-index: 5001 !important;
}
.p-dialog-mask.p-component-overlay {
	background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	box-shadow: none;
}
.p-overlaypanel .p-overlaypanel-content {
	padding: 1.25rem;
}
.p-overlaypanel .p-overlaypanel-close {
	background: $primary-color;
	color: #ffffff;
	width: 2rem;
	height: 2rem;
	transition: box-shadow 0.15s;
	border-radius: 50%;
	position: absolute;
	top: -1rem;
	right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
	background: $hover-color;
	color: #ffffff;
}
.p-overlaypanel:after {
	border: solid transparent;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
	border: solid transparent;
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
	border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
	border-top-color: rgba(0, 0, 0, 0.2);
}

.p-sidebar {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: none;
	top: 51px;
	border: none;
}
.p-sidebar-left {
	width: 260px;
}
.p-sidebar .p-sidebar-close,
.p-sidebar .p-sidebar-icon {
	width: 2rem;
	height: 2rem;
	color: $text-color-secondary;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
}
.p-sidebar .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-icon:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
}
.p-sidebar .p-sidebar-close:focus,
.p-sidebar .p-sidebar-icon:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0px 1px 0px #c9c9c9;
	border: none;
}
.p-sidebar-mask {
	background-color: transparent;
}
.p-tooltip .p-tooltip-text {
	background: $text-color;
	color: #ffffff;
	padding: 0.5rem 0.75rem;
	box-shadow: none;
	border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
	border-right-color: $text-color;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
	border-left-color: $text-color;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
	border-top-color: $text-color;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
	border-bottom-color: $text-color;
}

.p-fileupload {
	display: flex;
	flex-direction: column;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
	padding: 5px;
	border: none !important;
	background: white !important;
	color: black;
	display: flex;
	flex-direction: row-reverse;
	width: 291px;

	&:hover {
		background-color: white;
		color: black;
		box-shadow: none;
	}
}
.p-fileupload .p-fileupload-buttonbar .p-button-icon-only {
	border: none !important;
	background: none !important;
}
.p-fileupload .p-fileupload-buttonbar .p-button .p-button-icon {
	display: block;
	font-size: 16px;
	color: #663366;
}
.p-fileupload .p-fileupload-buttonbar .p-button .p-button-label {
	font-family: Lato;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	width: 231px;
	flex: auto;
	letter-spacing: 0;
}

.p-fileupload-choose{
	padding-left: 0 !important; 
}

.p-fileupload .p-fileupload-content {
	display: flex;
	align-items: center;
	margin-top: 0.25rem;
	justify-content: center;
}
.p-fileupload .p-fileupload-content .p-fileupload-files img {
	object-fit: cover;
	height: 150px;
	max-width: 275px;
}

.p-fileupload .p-fileupload-row div img{
	object-fit: cover;
	height: 150px;
	min-width: 150px;
	max-width: 275px;
}
.p-fileupload .p-fileupload-content svg {
	color: $text-color;
}
.p-fileupload .p-progressbar,
.p-fileupload .p-fileupload-filename {
	display: none;
}
.p-fileupload .p-fileupload-filename ~ div {
	display: none;
}

.p-fileupload.p-fileupload-advanced .p-message {
	margin-top: 0;
}

.p-breadcrumb {
	background: $background-color;
	border: 0 none;
	border-radius: 4px;
	padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
	transition: box-shadow 0.15s;
	border-radius: 4px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
	color: $primary-color;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
	color: $primary-color;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
	margin: 0 0.5rem 0 0.5rem;
	color: $text-color-secondary;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
	color: $text-color-secondary;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
	color: $text-color-secondary;
}

.p-contextmenu {
	padding: 0.5rem 0;
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
	width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $text-color;
	border-radius: 0;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
	color: $text-color;
	margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: $text-color;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: $text-color;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: $text-color;
}
.p-contextmenu .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-contextmenu .p-submenu-list {
	padding: 0.5rem 0;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
	background: #e9ecef;
}
.p-contextmenu
	.p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-menuitem-text {
	color: $text-color;
}
.p-contextmenu
	.p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-menuitem-icon,
.p-contextmenu
	.p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-submenu-icon {
	color: $text-color;
}
.p-contextmenu .p-menu-separator {
	border-top: 1px solid $components-border-color;
	margin: 0.5rem 0;
}
.p-contextmenu .p-submenu-icon {
	font-size: 0.875rem;
}

.p-megamenu {
	padding: 0.5rem 1rem;
	background: $background-color;
	color: rgba(0, 0, 0, 0.9);
	border: 0 none;
	border-radius: 4px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
	padding: 1rem;
	color: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem
	> .p-menuitem-link
	.p-menuitem-text {
	color: rgba(0, 0, 0, 0.5);
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem
	> .p-menuitem-link
	.p-menuitem-icon {
	color: rgba(0, 0, 0, 0.5);
	margin-right: 0.5rem;
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem
	> .p-menuitem-link
	.p-submenu-icon {
	color: rgba(0, 0, 0, 0.5);
	margin-left: 0.5rem;
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover {
	background: transparent;
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-text {
	color: rgba(0, 0, 0, 0.7);
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-icon {
	color: rgba(0, 0, 0, 0.7);
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover
	.p-submenu-icon {
	color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link,
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover {
	background: transparent;
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-menuitem-text,
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-text {
	color: rgba(0, 0, 0, 0.9);
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-menuitem-icon,
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-icon {
	color: rgba(0, 0, 0, 0.9);
}
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-submenu-icon,
.p-megamenu
	.p-megamenu-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover
	.p-submenu-icon {
	color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $text-color;
	border-radius: 0;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
	color: $text-color;
	margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: $text-color;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: $text-color;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: $text-color;
}
.p-megamenu .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-megamenu .p-megamenu-panel {
	background: #ffffff;
	color: $text-color;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-megamenu .p-megamenu-submenu-header {
	margin: 0;
	padding: 0.75rem 1rem;
	color: $text-color;
	background: #ffffff;
	font-weight: 600;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-megamenu .p-megamenu-submenu {
	padding: 0.5rem 0;
	width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
	border-top: 1px solid $components-border-color;
	margin: 0.5rem 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
	background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-megamenu.p-megamenu-vertical {
	width: 12.5rem;
	padding: 0.5rem 0;
}

.p-menu {
	padding: 0.5rem 0;
	background: #ffffff;
	color: $text-color;
	border: 1px solid $components-border-color;
	border-radius: 4px;
	width: 12.5rem;
}
.p-menu .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $text-color;
	border-radius: 0;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
	color: $text-color;
	margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: $text-color;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: $text-color;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: $text-color;
}
.p-menu .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-menu.p-menu-overlay {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-menu .p-submenu-header {
	margin: 0;
	padding: 0.75rem 1rem;
	color: $text-color;
	background: #ffffff;
	font-weight: 600;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
	border-top: 1px solid $components-border-color;
	margin: 0.5rem 0;
}

.p-menubar {
	padding: 0.5rem 1rem;
	background: $background-color;
	color: rgba(0, 0, 0, 0.9);
	border: 0 none;
	border-radius: 4px;
}
.p-menubar .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $text-color;
	border-radius: 0;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
	color: $text-color;
	margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: $text-color;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: $text-color;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: $text-color;
}
.p-menubar .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
	padding: 1rem;
	color: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link
	.p-menuitem-text {
	color: rgba(0, 0, 0, 0.5);
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link
	.p-menuitem-icon {
	color: rgba(0, 0, 0, 0.5);
	margin-right: 0.5rem;
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link
	.p-submenu-icon {
	color: rgba(0, 0, 0, 0.5);
	margin-left: 0.5rem;
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover {
	background: transparent;
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-text {
	color: rgba(0, 0, 0, 0.7);
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-icon {
	color: rgba(0, 0, 0, 0.7);
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover
	.p-submenu-icon {
	color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link,
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover {
	background: transparent;
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-menuitem-text,
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-text {
	color: rgba(0, 0, 0, 0.9);
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-menuitem-icon,
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-icon {
	color: rgba(0, 0, 0, 0.9);
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-submenu-icon,
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover
	.p-submenu-icon {
	color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-submenu-list {
	padding: 0.5rem 0;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
	width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
	border-top: 1px solid $components-border-color;
	margin: 0.5rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
	font-size: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
	background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}

@media screen and (max-width: 960px) {
	.p-menubar {
		position: relative;
	}
	.p-menubar .p-menubar-button {
		display: flex;
		width: 2rem;
		height: 2rem;
		color: rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		transition: box-shadow 0.15s;
	}
	.p-menubar .p-menubar-button:hover {
		color: rgba(0, 0, 0, 0.7);
		background: transparent;
	}
	.p-menubar .p-menubar-button:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem $box-shadow;
	}
	.p-menubar .p-menubar-root-list {
		position: absolute;
		display: none;
		padding: 0.5rem 0;
		background: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.15);
		box-shadow: none;
		width: 100%;
	}
	.p-menubar .p-menubar-root-list .p-menu-separator {
		border-top: 1px solid $components-border-color;
		margin: 0.5rem 0;
	}
	.p-menubar .p-menubar-root-list .p-submenu-icon {
		font-size: 0.875rem;
	}
	.p-menubar .p-menubar-root-list > .p-menuitem {
		width: 100%;
		position: static;
	}
	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
		padding: 0.75rem 1rem;
		color: $text-color;
		border-radius: 0;
		transition: box-shadow 0.15s;
		user-select: none;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link
		.p-menuitem-text {
		color: $text-color;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link
		.p-menuitem-icon {
		color: $text-color;
		margin-right: 0.5rem;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link
		.p-submenu-icon {
		color: $text-color;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link:not(.p-disabled):hover {
		background: #e9ecef;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link:not(.p-disabled):hover
		.p-menuitem-text {
		color: $text-color;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link:not(.p-disabled):hover
		.p-menuitem-icon {
		color: $text-color;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link:not(.p-disabled):hover
		.p-submenu-icon {
		color: $text-color;
	}
	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: inset 0 0 0 0.15rem $box-shadow;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem
		> .p-menuitem-link
		> .p-submenu-icon {
		margin-left: auto;
		transition: transform 0.15s;
	}
	.p-menubar
		.p-menubar-root-list
		> .p-menuitem.p-menuitem-active
		> .p-menuitem-link
		> .p-submenu-icon {
		transform: rotate(-180deg);
	}
	.p-menubar .p-menubar-root-list .p-submenu-list {
		width: 100%;
		position: static;
		box-shadow: none;
		border: 0 none;
	}
	.p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
		transition: transform 0.15s;
		transform: rotate(90deg);
	}
	.p-menubar
		.p-menubar-root-list
		.p-submenu-list
		.p-menuitem-active
		> .p-menuitem-link
		> .p-submenu-icon {
		transform: rotate(-90deg);
	}
	.p-menubar .p-menubar-root-list .p-menuitem {
		width: 100%;
		position: static;
	}
	.p-menubar .p-menubar-root-list ul li a {
		padding-left: 2.25rem;
	}
	.p-menubar .p-menubar-root-list ul li ul li a {
		padding-left: 3.75rem;
	}
	.p-menubar .p-menubar-root-list ul li ul li ul li a {
		padding-left: 5.25rem;
	}
	.p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
		padding-left: 6.75rem;
	}
	.p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
		padding-left: 8.25rem;
	}
	.p-menubar.p-menubar-mobile-active .p-menubar-root-list {
		display: flex;
		flex-direction: column;
		top: 100%;
		left: 0;
		z-index: 1;
	}
}
.p-panelmenu .p-panelmenu-header > a {
	padding: 1rem 1.25rem;
	border: 1px solid $components-border-color;
	color: $text-color;
	background: $background-color;
	font-weight: 600;
	border-radius: 4px;
	transition: box-shadow 0.15s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
	margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
	margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
	background: #e9ecef;
	border-color: $components-border-color;
	color: $text-color;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
	margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
	background: $background-color;
	border-color: $components-border-color;
	color: $text-color;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
	border-color: $components-border-color;
	background: #e9ecef;
	color: $text-color;
}
.p-panelmenu .p-panelmenu-content {
	padding: 0.5rem 0;
	border: 1px solid $components-border-color;
	background: #ffffff;
	color: $text-color;
	margin-bottom: 0;
	border-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $text-color;
	border-radius: 0;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-panelmenu
	.p-panelmenu-content
	.p-menuitem
	.p-menuitem-link
	.p-menuitem-text {
	color: $text-color;
}
.p-panelmenu
	.p-panelmenu-content
	.p-menuitem
	.p-menuitem-link
	.p-menuitem-icon {
	color: $text-color;
	margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-panelmenu
	.p-panelmenu-content
	.p-menuitem
	.p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}
.p-panelmenu
	.p-panelmenu-content
	.p-menuitem
	.p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-text {
	color: $text-color;
}
.p-panelmenu
	.p-panelmenu-content
	.p-menuitem
	.p-menuitem-link:not(.p-disabled):hover
	.p-menuitem-icon {
	color: $text-color;
}
.p-panelmenu
	.p-panelmenu-content
	.p-menuitem
	.p-menuitem-link:not(.p-disabled):hover
	.p-submenu-icon {
	color: $text-color;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-panelmenu
	.p-panelmenu-content
	.p-menuitem
	.p-menuitem-link
	.p-panelmenu-icon {
	margin-right: 0.5rem;
}
.p-panelmenu
	.p-panelmenu-content
	.p-submenu-list:not(.p-panelmenu-root-submenu) {
	padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
	margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
	border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
	border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
	border-top: 0 none;
}
.p-panelmenu
	.p-panelmenu-panel:not(:first-child)
	.p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
	> a,
.p-panelmenu
	.p-panelmenu-panel:not(:first-child)
	.p-panelmenu-header:not(.p-disabled).p-highlight:hover
	> a {
	border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.p-panelmenu
	.p-panelmenu-panel:last-child
	.p-panelmenu-header:not(.p-highlight)
	> a {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.p-slidemenu {
	padding: 0.5rem 0;
	background: #ffffff;
	color: $text-color;
	border: 1px solid $components-border-color;
	border-radius: 4px;
	width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $text-color;
	border-radius: 0;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
	color: $text-color;
	margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: $text-color;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: $text-color;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: $text-color;
}
.p-slidemenu .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-slidemenu.p-slidemenu-overlay {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-slidemenu .p-slidemenu-list {
	padding: 0.5rem 0;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
	background: #e9ecef;
}
.p-slidemenu
	.p-slidemenu.p-slidemenu-active
	> .p-slidemenu-link
	.p-slidemenu-text {
	color: $text-color;
}
.p-slidemenu
	.p-slidemenu.p-slidemenu-active
	> .p-slidemenu-link
	.p-slidemenu-icon,
.p-slidemenu
	.p-slidemenu.p-slidemenu-active
	> .p-slidemenu-link
	.p-slidemenu-icon {
	color: $text-color;
}
.p-slidemenu .p-slidemenu-separator {
	border-top: 1px solid $components-border-color;
	margin: 0.5rem 0;
}
.p-slidemenu .p-slidemenu-icon {
	font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
	padding: 0.75rem 1rem;
	color: $text-color;
}

.p-steps .p-steps-item .p-menuitem-link {
	background: transparent;
	transition: box-shadow 0.15s;
	border-radius: 4px;
	background: transparent;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
	color: $text-color;
	border: 1px solid $components-border-color;
	background: transparent;
	min-width: 2rem;
	height: 2rem;
	line-height: 2rem;
	font-size: 1.143rem;
	z-index: 1;
	border-radius: 4px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
	margin-top: 0.5rem;
	color: $text-color-secondary;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
	background: $primary-color;
	color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
	font-weight: 600;
	color: $text-color;
}
.p-steps .p-steps-item:before {
	content: ' ';
	border-top: 1px solid $components-border-color;
	width: 100%;
	top: 50%;
	left: 0;
	display: block;
	position: absolute;
	margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
	background: transparent;
	border: 1px solid $components-border-color;
	border-width: 0 0 1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
	margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
	border: solid;
	border-width: 1px;
	border-color: #ffffff #ffffff $components-border-color #ffffff;
	background: #ffffff;
	color: $text-color-secondary;
	padding: 0.75rem 1rem;
	font-weight: 600;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	transition: box-shadow 0.15s;
	margin: 0 0 -1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
	margin-right: 0.5rem;
}
.p-tabmenu
	.p-tabmenu-nav
	.p-tabmenuitem
	.p-menuitem-link:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-tabmenu
	.p-tabmenu-nav
	.p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
	.p-menuitem-link {
	background: #ffffff;
	border-color: $components-border-color;
	color: $text-color-secondary;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
	background: #ffffff;
	border-color: $components-border-color $components-border-color #ffffff
		$components-border-color;
	color: #495057;
}

.p-tieredmenu {
	padding: 0.5rem 0;
	background: #ffffff;
	color: $text-color;
	border: 1px solid $components-border-color;
	border-radius: 4px;
	width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
	padding: 0.75rem 1rem;
	color: $text-color;
	border-radius: 0;
	transition: box-shadow 0.15s;
	user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
	color: $text-color;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
	color: $text-color;
	margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
	background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color: $text-color;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
	color: $text-color;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
	color: $text-color;
}
.p-tieredmenu .p-menuitem-link:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: inset 0 0 0 0.15rem $box-shadow;
}
.p-tieredmenu.p-tieredmenu-overlay {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-tieredmenu .p-submenu-list {
	padding: 0.5rem 0;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: none;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
	background: #e9ecef;
}
.p-tieredmenu
	.p-menuitem.p-menuitem-active
	> .p-menuitem-link
	.p-menuitem-text {
	color: $text-color;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
	color: $text-color;
}
.p-tieredmenu .p-menu-separator {
	border-top: 1px solid $components-border-color;
	margin: 0.5rem 0;
}
.p-tieredmenu .p-submenu-icon {
	font-size: 0.875rem;
}

.p-inline-message {
	padding: 0.5rem 0.75rem;
	margin: 0;
	border-radius: 4px;
}
.p-inline-message.p-inline-message-info {
	background: #cce5ff;
	border: solid #b8daff;
	border-width: 0px;
	color: #004085;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
	color: #004085;
}
.p-inline-message.p-inline-message-success {
	background: #d4edda;
	border: solid #c3e6cb;
	border-width: 0px;
	color: #155724;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
	color: #155724;
}
.p-inline-message.p-inline-message-warn {
	background: #fff3cd;
	border: solid #ffeeba;
	border-width: 0px;
	color: #856404;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
	color: #856404;
}
.p-inline-message.p-inline-message-error {
	background: #f8d7da;
	border: solid #f5c6cb;
	border-width: 0px;
	color: #721c24;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
	color: #721c24;
}
.p-inline-message .p-inline-message-icon {
	font-size: 1rem;
	margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
	font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
	margin-right: 0;
}

.p-message {
	margin: 1rem 0;
	border-radius: 4px;
}
.p-message .p-message-wrapper {
	padding: 1rem 1.25rem;
}
.p-message .p-message-close {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background: transparent;
	transition: box-shadow 0.15s;
}
.p-message .p-message-close:hover {
	background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-message.p-message-info {
	background: #cce5ff;
	border: solid #b8daff;
	border-width: 1px;
	color: #004085;
}
.p-message.p-message-info .p-message-icon {
	color: #004085;
}
.p-message.p-message-info .p-message-close {
	color: #004085;
}
.p-message.p-message-success {
	background: #d4edda;
	border: solid #c3e6cb;
	border-width: 1px;
	color: #155724;
}
.p-message.p-message-success .p-message-icon {
	color: #155724;
}
.p-message.p-message-success .p-message-close {
	color: #155724;
}
.p-message.p-message-warn {
	background: #fff3cd;
	border: solid #ffeeba;
	border-width: 1px;
	color: #856404;
}
.p-message.p-message-warn .p-message-icon {
	color: #856404;
}
.p-message.p-message-warn .p-message-close {
	color: #856404;
}
.p-message.p-message-error {
	background: #f8d7da;
	border: solid #f5c6cb;
	border-width: 1px;
	color: #721c24;
}
.p-message.p-message-error .p-message-icon {
	color: #721c24;
}
.p-message.p-message-error .p-message-close {
	color: #721c24;
}
.p-message .p-message-text {
	font-size: 1rem;
	font-weight: 500;
}
.p-message .p-message-icon {
	font-size: 1.5rem;
	margin-right: 0.5rem;
}

.p-toast {
	opacity: 1;
	z-index: 7000 !important;
}
.p-toast .p-toast-message {
	margin: 0 0 1rem 0;
	box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}
.p-toast .p-toast-message .p-toast-message-content {
	padding: 1rem;
	border-width: 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
	margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
	font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
	font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
	margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background: transparent;
	transition: box-shadow 0.15s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
	background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}
.p-toast .p-toast-message.p-toast-message-info {
	background: #cce5ff;
	border: solid #b8daff;
	border-width: 1px;
	color: #004085;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
	color: #004085;
}
.p-toast .p-toast-message.p-toast-message-success {
	background: #d4edda;
	border: solid #c3e6cb;
	border-width: 1px;
	color: #155724;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
	color: #155724;
}
.p-toast .p-toast-message.p-toast-message-warn {
	background: #fff3cd;
	border: solid #ffeeba;
	border-width: 1px;
	color: #856404;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
	color: #856404;
}
.p-toast .p-toast-message.p-toast-message-error {
	background: #f8d7da;
	border: solid #f5c6cb;
	border-width: 1px;
	color: #721c24;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
	color: #721c24;
}

.p-galleria .p-galleria-close {
	margin: 0.5rem;
	background: transparent;
	color: $background-color;
	width: 4rem;
	height: 4rem;
	transition: box-shadow 0.15s;
	border-radius: 4px;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
	font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
	background: rgba(255, 255, 255, 0.1);
	color: $background-color;
}
.p-galleria .p-galleria-item-nav {
	background: transparent;
	color: $background-color;
	width: 4rem;
	height: 4rem;
	transition: box-shadow 0.15s;
	border-radius: 4px;
	margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
	font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
	background: rgba(255, 255, 255, 0.1);
	color: $background-color;
}
.p-galleria .p-galleria-caption {
	background: rgba(0, 0, 0, 0.5);
	color: $background-color;
	padding: 1rem;
}
.p-galleria .p-galleria-indicators {
	padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
	background-color: #e9ecef;
	width: 1rem;
	height: 1rem;
	transition: box-shadow 0.15s;
	border-radius: 4px;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
	background: $components-border-color;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
	background: $primary-color;
	color: #ffffff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
	margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
	margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
	background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem
	.p-galleria-indicators
	.p-galleria-indicator
	button {
	background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem
	.p-galleria-indicators
	.p-galleria-indicator
	button:hover {
	background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem
	.p-galleria-indicators
	.p-galleria-indicator.p-highlight
	button {
	background: $primary-color;
	color: #ffffff;
}
.p-galleria .p-galleria-thumbnail-container {
	background: rgba(0, 0, 0, 0.9);
	padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
	margin: 0.5rem;
	background-color: transparent;
	color: $background-color;
	width: 2rem;
	height: 2rem;
	transition: box-shadow 0.15s;
	border-radius: 4px;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
	background: rgba(255, 255, 255, 0.1);
	color: $background-color;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
	transition: box-shadow 0.15s;
}
.p-galleria
	.p-galleria-thumbnail-container
	.p-galleria-thumbnail-item-content:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}

.p-galleria-mask.p-component-overlay {
	background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
	background-color: $components-border-color;
	border-radius: 4px;
}
.p-avatar.p-avatar-lg {
	width: 3rem;
	height: 3rem;
	font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
	font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
	width: 4rem;
	height: 4rem;
	font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
	font-size: 2rem;
}

.p-avatar-group .p-avatar {
	border: 2px solid #ffffff;
}

.p-chip {
	background-color: $components-border-color;
	color: $text-color;
	border-radius: 16px;
	padding: 0 0.75rem;
}
.p-chip .p-chip-text {
	line-height: 1.5;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
	margin-right: 0.5rem;
}
.p-chip img {
	width: 2rem;
	height: 2rem;
	margin-left: -0.75rem;
	margin-right: 0.5rem;
}
.p-chip .p-chip-remove-icon {
	border-radius: 4px;
	transition: box-shadow 0.15s;
	margin-left: 0.5rem;
}
.p-chip .p-chip-remove-icon:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}

.p-scrolltop {
	width: 3rem;
	height: 3rem;
	border-radius: 4px;
	box-shadow: none;
	transition: box-shadow 0.15s;
}
.p-scrolltop.p-link {
	background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
	background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
	font-size: 1.5rem;
	color: $background-color;
}

.p-skeleton {
	background-color: #e9ecef;
	border-radius: 4px;
}
.p-skeleton:after {
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0.4),
		rgba(255, 255, 255, 0)
	);
}

.p-tag {
	background: $primary-color;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: 700;
	padding: 0.25rem 0.4rem;
	border-radius: 4px;
}
.p-tag.p-tag-success {
	background-color: #28a745;
	color: #ffffff;
}
.p-tag.p-tag-info {
	background-color: #17a2b8;
	color: #ffffff;
}
.p-tag.p-tag-warning {
	background-color: #ffc107;
	color: $text-color;
}
.p-tag.p-tag-danger {
	background-color: $error-color;
	color: #ffffff;
}
.p-tag .p-tag-icon {
	margin-right: 0.25rem;
	font-size: 0.75rem;
}

.p-inplace .p-inplace-display {
	padding: 0.5rem 0.75rem;
	border-radius: 4px;
	transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
	background: #e9ecef;
	color: $text-color;
}
.p-inplace .p-inplace-display:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem $box-shadow;
}

.p-progressbar {
	border: 0 none;
	height: 1.5rem;
	background: #e9ecef;
	border-radius: 4px;
}
.p-progressbar .p-progressbar-value {
	border: 0 none;
	margin: 0;
	background: $primary-color;
}
.p-progressbar .p-progressbar-label {
	color: $text-color;
	line-height: 1.5rem;
}

.p-terminal {
	background: #ffffff;
	color: $text-color;
	border: 1px solid $components-border-color;
	padding: 1.25rem;
}
.p-terminal .p-terminal-input {
	font-size: 1rem;
	font-family: $font-family;
}

.p-blockui.p-component-overlay {
	background: rgba(0, 0, 0, 0.4);
}

.p-badge {
	background: $primary-color;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: 700;
	min-width: 1.5rem;
	height: 1.5rem;
	line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
	background-color: $text-color-secondary;
	color: #ffffff;
}
.p-badge.p-badge-success {
	background-color: #28a745;
	color: #ffffff;
}
.p-badge.p-badge-info {
	background-color: #17a2b8;
	color: #ffffff;
}
.p-badge.p-badge-warning {
	background-color: #ffc107;
	color: $text-color;
}
.p-badge.p-badge-danger {
	background-color: $error-color;
	color: #ffffff;
}
.p-badge.p-badge-lg {
	font-size: 1.125rem;
	min-width: 2.25rem;
	height: 2.25rem;
	line-height: 2.25rem;
}
.p-badge.p-badge-xl {
	font-size: 1.5rem;
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}

.p-tag {
	background: $primary-color;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: 700;
	padding: 0.25rem 0.4rem;
	border-radius: 4px;
}
.p-tag.p-tag-success {
	background-color: #28a745;
	color: #ffffff;
}
.p-tag.p-tag-info {
	background-color: #17a2b8;
	color: #ffffff;
}
.p-tag.p-tag-warning {
	background-color: #ffc107;
	color: $text-color;
}
.p-tag.p-tag-danger {
	background-color: $error-color;
	color: #ffffff;
}

/* Vendor extensions to the designer enhanced bootstrap compatibility */
.p-breadcrumb .p-breadcrumb-chevron {
	font-family: $font-family;
}
.p-breadcrumb .p-breadcrumb-chevron:before {
	content: '/';
}

/* Customizations to the designer theme should be defined here */
