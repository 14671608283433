.top-bar-user-text {
	font-size: 16px;
	line-height: 30px;
	font-style: normal;
	color: $text-color;
}

.top-bar-user-icon {
	color: #ffffff !important;
	background-color: $secondary-color;
	border-radius: 50%;
	width: 40px !important;
	height: 40px;
	display: flex;
	span {
		padding-bottom: 2px;
		padding-left: 6px;
		font-size: 20px !important;
	}
}
