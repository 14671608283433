.loginPage {
	@include linear-gradient-left($login-background-from, $login-background-to);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 90px;
	min-height: 100%;
}

.formTitle {
	font-family: 'Roboto';
	font-weight: 700;
	color: #825982;
	font-size: 20px;
	text-align: center;
	margin-bottom: 30px;
}

.p-field {
	width: 300px;
	margin-bottom: 30px;

	label {
		color: $textSecondaryColor;
	}
}

.p-password {
	display: block;
}

.buttonLogin {
	background-color: $boton-color;
	border: none;
	font-family: Lato;
	font-size: 14px;
	padding: 0.75rem;

	&:hover {
		background-color: $boton-color;
		box-shadow: 0 4px 1px -1px rgba(0, 0, 0, 0.25),
			0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
	}

	&:focus {
		box-shadow: 0 0 0 0;
	}
}
