.layout-footer {
	@include transition(margin-left $transitionDuration);
	background-color: $footerBgColor;
	padding: 1em 13em;
	z-index: 2000;
	font-size: $footer-font-size;
	box-shadow: 0 0 0 0.1rem $box-shadow;
	img {
		vertical-align: middle;
	}

	.footer-text {
		vertical-align: middle;
	}
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	text-align: center;
}
