.layout-menu-container {
	padding-bottom: 20px;
}

.layout-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		a {
			cursor: pointer;
			position: relative;
			text-decoration: none;
			display: block;
			border-top: 1px solid #c9c9c9;
			@include transition(color $transitionDuration);

			&:hover {
				background: #f6f6f6;
				> a {
					> span {
						color: #663366;
					}
				}
			}

			i {
				font-size: 18px;
				vertical-align: middle;
			}

			span {
				margin-left: 0.25em;
				vertical-align: middle;
				line-height: 18px;
				display: inline-block;
			}

			.menuitem-toggle-icon {
				position: absolute;
				top: 50%;
				right: 1em;
				margin-top: -9px;
			}

			&.router-link-active {
				font-weight: 700;
			}
		}

		.active-route {
			background-color: #f0ebf0;
			color: #663366;

			span {
				font-weight: bold;
				color: #663366;
			}
		}

		&.active-menuitem {
			> a {
				.menuitem-toggle-icon {
					@include icon-override('\e933');
				}
			}

			> ul {
				max-height: 500px;
			}
		}
	}

	> li {
		> a {
			padding: 1em 2em;

			span {
				font-size: 16px;
				color: #46494a;
			}
		}

		&:last-child {
			> a {
				box-shadow: none;
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			max-height: 0;
			overflow: hidden;
			@include transition-property(max-height);
			@include transition-duration(0.4s);
			@include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

			li {
				a {
					cursor: pointer;
					padding: 0.75em 0em;
					border-top: 1px solid #c9c9c9;
					border-bottom: none;

					span {
						font-size: 14px;
						color: #46494a;
						display: inline;
					}
				}
			}
		}
	}
}

.layout-sidebar-light {
	@include linear-gradient($menuBgColorFirst, $menuBgColorLast);

	.layout-menu {
		> li {
			> a {
				border-top: 1px solid $menuitemBorderColor;
			}

			&:last-child {
				> a {
					border-bottom: 1px solid $menuitemBorderColor;
				}
			}

			ul {
				background-color: $menuitemActiveBgColor;
			}
		}

		li {
			a {
				color: $menuitemColor;

				&.router-link-active {
					color: $menuitemActiveRouteColor;
				}

				&:hover {
					color: $menuitemHoverColor;
				}
			}

			&.active-menuitem {
				> a {
					background-color: $menuitemActiveBgColor;
					color: $menuitemActiveColor;
				}
			}
			.active-route {
				background-color: #f0ebf0;
				color: #663366;
			}
		}
	}
}

.layout-sidebar-dark {
	@include linear-gradient($menuDarkBgColorFirst, $menuDarkBgColorLast);

	.layout-menu {
		> li {
			> a {
				border-top: 1px solid $menuitemDarkBorderColor;
			}

			&:last-child {
				> a {
					border-bottom: 1px solid $menuitemDarkBorderColor;
				}
			}

			ul {
				background-color: $menuitemDarkActiveBgColor;
			}
		}

		li {
			a {
				color: $menuitemDarkColor;

				&.router-link-active {
					color: $menuitemActiveRouteColor;
				}

				&:hover {
					color: $menuitemDarkHoverColor;
				}
			}

			&.active-menuitem {
				> a {
					background-color: $menuitemDarkActiveBgColor;
					color: $menuitemDarkActiveColor;
				}
			}
			&.active-route {
				background-color: $menuitemActiveBgColor;
				color: $menuitemActiveColor;
			}
		}
	}
}
